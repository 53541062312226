import React, { Component } from 'react';
import moment from 'moment';

var fechacompleta = moment().format('LL');
class Contract extends Component{
    
    constructor(props){
        super(props);
        this.state = {
          
        }
    }

render(){
    return(
        <div>
                  <p  className={'contractBold'}>The present Individual Client Agreement (the “Agreement”) is entered into as of the day {fechacompleta} between CAPITALIKA MARKETS, LLC. (CAPITALIKA MARKETS LLC) and {this.props.name} (“Client”).</p>
                  WHEREAS, CAPITALIKA MARKETS LLC is a dealer who is able to purchase or sell, by or for itself or as a
                  broker, Contracts For Difference on Equities, ETF, Futures and Forex on the aforementioned
                  contracts (collectively, “CFD’s”) on Equities, Futures, Currency and any similar instruments
                  (collectively referred to as OTC “Contracts”),; NOW, THEREFORE, in consideration of CAPITALIKA MARKETS 
                  LLC, or any of its affiliates, agreeing to carry one or more accounts of the undersigned and to
                  provide services to Client in connection with the purchase and sale of CFD’s which may be
                  purchased or sold by or through CAPITALIKA MARKETS LLC for Client’s accounts(s), Client agrees as follows:
                  <p  className={'contractsubTitles'}>1. ESTABLISHMENT OF ACCOUNT</p>
                  Client understands, acknowledges and agrees that by executing this Agreement, and
                  subject to the terms and conditions of this Agreement, an account will be established in the
                  Client’s name at CAPITALIKA MARKETS LLC, Limited, or any CAPITALIKA MARKETS LLC affiliate (collectively CAPITALIKA MARKETS 
                  LLC”), that CAPITALIKA MARKETS LLC, in its sole discretion, may elect. Client further
                  acknowledges that, subject to the terms and conditions herein, CAPITALIKA MARKETS LLC shall act as
                  counterparty to all transactions conducted under this Agreement.
                  <p  className={'contractsubTitles'}>2. AUTHORIZATION TO TRADE</p>
                  CAPITALIKA MARKETS LLC is hereby authorized to purchase and sell OTC Contracts for Client’s
                  account(s) in accordance with Client’s oral, written or electronic instructions. Unless
                  instructed by Client to the contrary in writing, CAPITALIKA MARKETS LLC is authorized to execute all
                  orders with itself as counterparty or with such other counterparties as CAPITALIKA MARKETS LLC
                  deems appropriate.
                  <p  className={'contractsubTitles'}>3. GOVERNMENTAL, COUNTER PARTY INSTITUTION AND INTER-BANK SYSTEM
                  RULES</p>
                  All transactions under this Agreement shall be subject to the constitution, bylaws, rules,
                  regulations, customs, usage, rules and interpretations of the counterparty institution or
                  other inter-bank market (and its clearing organization, if any) where executed and to all
                  applicable laws of St. Vincent and the Grenadines. If any statute shall hereafter be
                  enacted or any rule or regulation shall hereafter be adopted by any governmental
                  authority, which shall be binding upon CAPITALIKA MARKETS LLC, this Agreement shall be deemed
                  modified or superseded, as the case may be, by the applicable provisions of such statute,
                  rule or regulation, and all other provisions of this Agreement and provisions so modified
                  shall in all respects continue in full force and effect.<p></p>
                  Client acknowledges that all transactions under this Agreement are subject to the
                  aforementioned regulatory requirements.
                  <p  className={'contractsubTitles'}>4. MARGIN AND DEPOSIT REQUIREMENTS</p>
                  Client shall deposit with CAPITALIKA MARKETS LLC collateral or margin in such amounts that CAPITALIKA MARKETS 
                  LLC, in its sole discretion, may require. Margin requirements will vary dependent on
                  the electronic trading system and products traded. CAPITALIKA MARKETS LLC may change margin
                  requirements at any time.<p></p>
                  Client agrees to deposit by immediate wire transfer such additional margin when and as
                  required by CAPITALIKA MARKETS LLC and will promptly meet all margin calls by such methods as
                  CAPITALIKA MARKETS LLC in its sole discretion designates. CAPITALIKA MARKETS LLC may at any time proceed
                  to liquidate.<p></p>
                  Client’s account in accordance with paragraph 10 below, and any failure by CAPITALIKA MARKETS 
                  LLC to enforce its rights hereunder shall not be deemed a waiver by CAPITALIKA MARKETS LLC
                  to enforce its rights thereafter. No previous collateral or margin requirement by CAPITALIKA MARKETS 
                  LLC shall preclude CAPITALIKA MARKETS LLC from increasing that requirement without prior
                  notice. Margin calls are conclusive and binding unless objected to immediately in writing.
                  Client understands that a margin call may occur even in instances where account positions
                  are hedged, given spreads may widen as set forth in paragraph 7 herein, causing
                  remaining margin to diminish. Should remaining margin be insufficient to maintain open
                  positions, the account may sustain a margin call, closing out any open positions in the
                  account.
                  <p  className={'contractsubTitles'}>5. POSITION LIMITATION, PROFITS AND EXTRAORDINARY VENTS</p>
                  CAPITALIKA MARKETS LLC retains the right to limit the amount and/or total number of open positions
                  that Client may acquire or maintain at CAPITALIKA MARKETS LLC. CAPITALIKA MARKETS LLC reserves the right to
                  refuse to accept any order. CAPITALIKA MARKETS LLC does not guarantee any or all profits resulting
                  from trading entered into the system(s) of CAPITALIKA MARKETS LLC, including but not limited to,
                  profits realized during perceived normal market conditions, extraordinary events or system
                  malfunctions. CAPITALIKA MARKETS LLC shall not be responsible for any loss or damage caused,
                  directly or indirectly, by any events, actions or omissions beyond the control of CAPITALIKA MARKETS 
                  LLC including, without limitation, loss or damage resulting, directly or indirectly, from
                  government restrictions, war, strikes, labor disruptions and terrorist acts. CAPITALIKA MARKETS LLC
                  shall not be liable for damages, including lost profits or trading losses caused by delays or
                  or failure of any transmission or communication facilities, unauthorized access, theft or
                  inaccuracies in pricing or the transmission of orders or information due to a breakdown in
                  profits Client obtains through the trading of delayed or inaccurate pricing.
                  systems failures. Client understands CAPITALIKA MARKETS LLC is authorized to reclaim any trading
                  <p  className={'contractsubTitles'}>6. PRICING, SCALPING, ORDERS AND GAPPING MARKETS</p>
                  Client understands and agrees that he/she will not enter into transactions for purposes of
                  scalping, arbitrage or to intentionally exploit or manipulate any inaccuracy, regardless of
                  how minor, major, obvious or unobvious, in any rate or pricing offered by CAPITALIKA MARKETS LLC
                  whether through the usage of scripts, codes, electronic advisors, software or any other
                  form of computer program or manual trading.<p></p>
                  CAPITALIKA MARKETS LLC defines scalping as any transaction which remains open for a period of
                  less than three minutes in duration. Client understands CAPITALIKA MARKETS LLC is authorized to
                  retroactively cancel transactions found, in CAPITALIKA MARKETS LLC sole discretion, to be
                  transactions entered into in order to exploit or manipulate pricing by means as set forth
                  within this Section.<p></p>
                  CAPITALIKA MARKETS LLC is then authorized to reclaim any trading profits Client obtains through the
                  entering of transactions for purposes of scalping, arbitrage or intentional exploitation of
                  inaccuracies of rates/ pricing offered by CAPITALIKA MARKETS LLC. Client acknowledges and
                  understands that CAPITALIKA MARKETS LLC does not guarantee the execution of orders at specific
                  prices, including conditional orders such as stops and/or limits, specifically at times of
                  increased volatility or volume, including market news announcements, due to gapping
                  markets, greyed out pricing, and market liquidity.<p></p>
                  Client understands and agrees that should they receive off-market prices, for any reason,
                  including but not limited to, delays, malfunctions or manual trading errors, CAPITALIKA MARKETS LLC
                  shall retroactively cancel such trades and may elect to leave such trades canceled or
                  reopen such trades at the correct market price, Client understands that orders received
                  during instances of increased market volatility will be filled at the next best price available
                  or the fair market value.<p></p>
                  Client acknowledges that during instances of gapping markets, conditional orders,
                  specifically stop losses, will be filled at the next available price following a gapping market.
                  Pricing can gap past the price set forth in conditional orders, resulting in clients losing
                  more funds than initially intended.<p></p>
                  Client acknowledges that due to such risks, CAPITALIKA MARKETS LLC encourages clients to utilize
                  market orders rather than conditional orders, as market orders are provided with a requote
                  and allow Clients to accept or reject the new price.<p></p>
                  Client agrees that CAPITALIKA MARKETS LLC is not responsible for any loses, including margin calls,
                  which occur as a result of conditional orders being filled during a gapping market or orders
                  being filled at certain prices as a result of greyed out pricing and/or limitations in market
                  liquidity.
                  <p  className={'contractsubTitles'}>7. WIDENED SPREADS</p>
                  Client understands and acknowledges that there may be instances where spreads widen
                  beyond the typical spreads offered. During volatile markets, such as news
                  announcements, spreads may widen substantially in order to compensate for increased
                  market volatility. Client is advised to utilize extreme caution when trading during increased
                  volatility in the markets, including major news events, as widened spreads can adversely
                  affect all positions in an account including hedged positions.
                  <p  className={'contractsubTitles'}>8. SETTLEMENT DATE, ROLLOVERS AND INTEREST</p>
                  CAPITALIKA MARKETS LLC is authorized, in its absolute discretion, to rollover or offset all or any
                  portion of positions maintained in CFD’s on currencies within Client’s Account(s),
                  maintained on any electronic trading platform at Client’s risk. A position carried forward
                  may be credited or debited interest charges until the position is closed. All CFD’s on
                  Futures are not rolled over at contract expiration. CAPITALIKA MARKETS LLC shall close all positions at
                  expiration of Futures contracts and client may, at his/her sole discretion, elect to purchase
                  a new contract. At its discretion, CAPITALIKA MARKETS LLC may elect to offer Client an interest free
                  account whereby positions carried forward will not be credited or debited interest charges.
                  
                  Client agrees and acknowledges that such interest free terms shall be granted by CAPITALIKA MARKETS 
                  LLC only as a CAPITALIKA MARKETS LLC shall have the authority to, at its own discretion, elect
                  to retroactively cancel such interest free term and account activity after making
                  determinations of the lack of authenticity of such representations, including determining
                  that Client is maintaining interest bearing accounts elsewhere. CAPITALIKA MARKETS LLC is
                  authorized to cancel all trading activity of such account since inception following its
                  determination and will return the original deposit(s) to Client.
                  <p  className={'contractsubTitles'}>9. COLLATERAL AND LENDING AGREEMENT</p>
                  All funds, securities, currencies, and other property of Client that CAPITALIKA MARKETS LLC or its
                  affiliates may at any time be carrying for Client (either individually, jointly with others or as
                  a guarantor of the account of any other person) or which may at any time be in its
                  possession or control or carried on the books of CAPITALIKA MARKETS LLC for any purpose, including
                  safekeeping, are to be held by CAPITALIKA MARKETS LLC as security and subject to a general lien
                  and right of set-off for all obligations and liabilities of Client to CAPITALIKA MARKETS LLC whether or
                  not CAPITALIKA MARKETS LLC has made advances in connection with such securities, currencies or
                  other property, and irrespective of the number of accounts Client may have with CAPITALIKA MARKETS 
                  LLC. CAPITALIKA MARKETS LLC may in its discretion, at any time and from time to time, without
                  notice to Client, apply and/or transfer any or all funds or other property of Client between
                  any of Client’s accounts. Client hereby also grants to CAPITALIKA MARKETS LLC the right to pledge,
                  re-pledge, hypothecate, invest or loan, either separately or with the property of other
                  Clients, to itself as broker or to others, any securities or other property of Client held by
                  CAPITALIKA MARKETS LLC as margin or collateral. CAPITALIKA MARKETS LLC shall at no time be required to
                  deliver to Client the identical property delivered to or purchased by CAPITALIKA MARKETS LLC for any
                  account of Client. Should Client take delivery of currencies through settlement of trades,
                  CAPITALIKA MARKETS LLC is obliged to make full payment for the delivery on 24 hours notice. If the
                  balance in the Client’s account is not adequate to pay for the delivery, the depository
                  receipts held in the name of Client or CAPITALIKA MARKETS LLC, as Client’s agent or nominee,
                  become property carried on margin in the Client’s account, since they are not fully paid for
                  by Client. This authorization shall apply to all accounts carried by CAPITALIKA MARKETS LLC for Client
                  and shall remain in full force until all accounts are fully paid for by Client or notice of
                  revocation is sent by CAPITALIKA MARKETS LLC.
                  <p  className={'contractsubTitles'}>10. LIQUIDATION OF ACCOUNTS/ DEFICIT BALANCES</p>
                  In the event of:
                  <p className={'contractClausules'}>A. the death or judicial declaration of incompetence of Client,</p>
                  <p className={'contractClausules'}>B. the filing of a petition in bankruptcy, or a petition for the appointment of a receiver, or
                  the institution of any insolvency or similar proceeding by or against Client,</p>
                  <p className={'contractClausules'}>C. the filing of an attachment against any of Client’s accounts carried by CAPITALIKA MARKETS LLC,</p>
                  <p className={'contractClausules'}>D. insufficient margin, or CAPITALIKA MARKETS LLC determination that any collateral deposited to
                  protect one or more accounts of Client is inadequate, regardless of current market
                  quotations, to secure the account,</p>
                  <p className={'contractClausules'}>E. Client’s failure to provide CAPITALIKA MARKETS LLC any information requested pursuant to this
                  agreement, or</p>
                  <p className={'contractClausules'}>F. any other circumstances or developments that CAPITALIKA MARKETS LLC deems appropriate for
                  its protection, then, in CAPITALIKA MARKETS LLC sole discretion, it may take one or more, or any
                  portion of, the following actions:</p>
                  <p className={'contractsubClausules'}>a. satisfy any obligation Client may have to CAPITALIKA MARKETS LLC, either directly or by
                  way of guaranty of surety, out of any of Client’s funds or property in its custody
                  or control,</p>
                  <p className={'contractsubClausules'}>b. sell or purchase any or all Contracts, securities or property held or carried for
                  Client, and</p>
                  <p className={'contractsubClausules'}>c. cancel any or all outstanding orders or contracts, or any other commitments
                  made on behalf of Client.</p>
                  Any of the above actions may be taken without demand for margin or additional margin,
                  without prior notice of sale or purchase or other notice to Client, Client’s personal
                  representatives, heirs, executors, administrators, trustees, legatees or assigns and
                  regardless of whether the ownership interest shall be solely Client’s or held jointly with
                  others. In liquidation of Client’s long or short positions, CAPITALIKA MARKETS LLC may, in its sole
                  discretion, offset in the same settlement or it may initiate new long or short positions in
                  order to establish a spread or straddle which in the sole discretion of CAPITALIKA MARKETS LLC may
                  be advisable to protect or reduce existing positions in a Client’s account.<p></p>
                  Any sales or purchases hereunder may be made at CAPITALIKA MARKETS LLC discretion with any
                  inter-bank or other market where such business is then usually transacted or at a public
                  auction or private sale, and CAPITALIKA MARKETS LLC may purchase the whole or any part thereof
                  free from any right of redemption. Client shall at all times be liable for the payment of any
                  deficit balance in Client’s account(s) upon demand by CAPITALIKA MARKETS LLC and in all cases,
                  Client shall be liable for any deficiency remaining in Client’s account(s) in the event of the
                  liquidation thereof in whole or in part by CAPITALIKA MARKETS LLC or by Client. In the event the
                  proceeds realized pursuant to this authorization are insufficient for the payment of all
                  obligations and liabilities of Client owed to CAPITALIKA MARKETS LLC, Client shall promptly pay upon
                  demand, the deficit together with interest thereon at a rate equal to three points above the
                  then prevailing prime rate at CAPITALIKA MARKETS LLC principal bank or the maximum interest rate
                  allowed by law, whichever is lower.<p></p>
                  Client agrees to pay and shall be liable for all reasonable costs and expenses of collection,
                  including but not limited to, attorney’s fees, witness fees and travel expenses. In the event
                  CAPITALIKA MARKETS LLC incurs expenses other than for the collection of deficits, with respect to
                  any of Client’s account(s), Client agrees to pay such expenses.
                  <p  className={'contractsubTitles'}>11. MONTHLY STATEMENTS AND CONFIRMATIONS</p>
                  Transaction confirmations of orders and statements of account s for Client and made
                  available solely online, shall be deemed correct and accurate and shall be conclusive and
                  binding upon Client if not objected to immediately to by Client, in writing to, within five (5)
                  business days of such activity being reviewable online. Failure to object shall be deemed
                  acceptance and ratification of all actions taken by CAPITALIKA MARKETS LLC or CAPITALIKA MARKETS LLC
                  agents. Client’s failure to receive a transaction confirmation shall not relieve Client of the
                  duty to inquire of CAPITALIKA MARKETS LLC and to object as set out herein.
                  <p  className={'contractsubTitles'}>12. COMMISSIONS AND FEES</p>
                  Client agrees to pay all charges relating to the CFD transactions conducted by or through
                  CAPITALIKA MARKETS LLC, including without limitation commissions, markups, markdowns,
                  transaction fees, transfer and cancellation charges, and inactive account fees. CAPITALIKA MARKETS 
                  LLC may adjust its charges without notice. All such charges shall be paid by Client
                  as incurred and deducted from Client’s account. CAPITALIKA MARKETS LLC may share such
                  commissions or markups and markdowns with third parties. Transaction fees are accessed
                  per trade on certain trading platforms. Funded accounts which remain inactive for a period
                  of six (6) months will be charged an inactive account fee of $50.00 USD. CAPITALIKA MARKETS LLC
                  failure to charge any such fees does not waive or invalid its right to deduct such fees in the
                  future.
                  <p  className={'contractsubTitles'}>13. CAPITALIKA MARKETS LLC RESPONSIBILITIES</p>
                  CAPITALIKA MARKETS LLC will not be responsible for delays in the transmission of orders due to a
                  breakdown or failure of transmission or communication facilities, electrical power outage or
                  for any other cause beyond CAPITALIKA MARKETS LLC control or anticipation. CAPITALIKA MARKETS LLC is not
                  responsible for loss of profits or general losses or other adverse affects realized by Client
                  accounts as a result of the cancellation of interest free terms pursuant to Section 8 herein,
                  gapping markets, re-pricing or instances of increased volatility in the markets. CAPITALIKA MARKETS 
                  LLC shall only be liable for its actions directly attributable to gross negligence, willful
                  default or fraud on the part of CAPITALIKA MARKETS LLC. CAPITALIKA MARKETS LLC shall not be liable for losses
                  arising from the default of any agent or any other party used by CAPITALIKA MARKETS LLC under this
                  Agreement. Since over-the-counter Contracts are not traded on an exchange, the prices at
                  which CAPITALIKA MARKETS LLC is willing to deal or the quotes published by CAPITALIKA MARKETS LLC.
                  <p  className={'contractsubTitles'}>14. IDENTITY VERIFICATION</p>
                  Client acknowledges and agrees that he/she is required to submit copies of unexpired
                  government issued identification and address verification documentation to CAPITALIKA MARKETS LLC
                  with a signed copy of this Agreement. Client further agrees that CAPITALIKA MARKETS LLC has the
                  authority to delay, cancel or otherwise postpone trading or the depositing/withdrawing of
                  funds, regardless of amount, until Client has provided copies of unexpired government
                  issued identification and/or address verification documentation as required. After receipt of
                  such documentation, CAPITALIKA MARKETS LLC reserves the right to request additional
                  documentation in order for CAPITALIKA MARKETS LLC to adequately verify the Client(s) identity. Client
                  understands that failure to provide such documentation shall authorize and obligate CAPITALIKA MARKETS 
                  LLC to report such to applicable government agencies.
                  <p  className={'contractsubTitles'}>15. CURRENCY FLUCTUATION RISK</p>
                  If Client directs CAPITALIKA MARKETS LLC to enter into any CFD transaction:
                  <p className={'contractClausules'}>A. any profit or loss arising as a result of a fluctuation in the exchange rate affecting such
                  currency will be entirely for the risk and account of Client;</p>
                  <p className={'contractClausules'}>B. all deposits for margin purposes if made in currency other than U .S. Dollars may be
                  converted to U .S. Dollars, in such amounts as CAPITALIKA MARKETS LLC may in its sole
                  discretion require; and</p>
                  <p className={'contractClausules'}>C. CAPITALIKA MARKETS LLC is authorized to convert funds in Client’s account for margin into and
                  from such foreign currency at a rate of exchange determined by CAPITALIKA MARKETS LLC in its
                  sole discretion on the basis of the then prevailing money market rates.</p>
                  <p  className={'contractsubTitles'}>16. RISK ACKNOWLEDGMENT</p>
                  Client acknowledges that investments in leveraged and non-leveraged transactions are
                  speculative, involve a high degree of risk, and are appropriate only for persons who can
                  assume risk of loss in excess of their margin deposit to carry CFD positions and may result
                  in significant losses that substantially exceed Client’s investment and margin deposit.
                  Client understands that CAPITALIKA MARKETS LLC does not guarantee any or all profits resulting from
                  trading on its system(s). Client represents and warrants to CAPITALIKA MARKETS LLC that Client is
                  willing and able, financially and otherwise, to assume the risk of trading in CFD
                  transactions, and in consideration of CAPITALIKA MARKETS LLC carrying his/her account(s), Client
                  agrees not to hold CAPITALIKA MARKETS LLC responsible for losses incurred through trading. Client
                  recognizes that guarantees of profit or freedom from loss are impossible in trading
                  Contracts. Client acknowledges that Client has received no such guarantees from CAPITALIKA MARKETS 
                  LLC or from any of its introducing or referring agents or other entities with whom
                  Client is conducting his/ her CAPITALIKA MARKETS LLC account and has not entered into this
                  Agreement in consideration of or in reliance upon any such guarantees or similar
                  representations. If Client has received any such guarantees or inducements, Client is
                  advised to report such activity to CAPITALIKA MARKETS LLC as promptly as possible.
                  <p  className={'contractsubTitles'}>17. CLIENT REPRESENTATIONS AND WARRANTIES</p>
                  Client represents and warrants to CAPITALIKA MARKETS LLC that:
                  <p className={'contractClausules'}>A. Client is of sound mind, legal age and legal competence,</p>
                  <p className={'contractClausules'}>B. no person other than Client has or will have an interest in Client’s account(s),</p>
                  <p className={'contractClausules'}>C. regardless of any subsequent determination to the contrary, Client is suitable to trade
                  Contracts and understands the risk inherent in such trading,</p>
                  <p className={'contractClausules'}>D. Client confirms that all funds deposited to his/her account are the property of the
                  account owner and of no other person or entity,</p>
                  <p className={'contractClausules'}>E. should Client request an interest free account, he/she maintains no other cinterestbearing account at another institution,</p>
                  <p className={'contractClausules'}>F. Client is not a resident or citizen of the United States or Canada as defined under
                  applicable law, and</p>
                  <p className={'contractClausules'}>G. all the information provided by Client to CAPITALIKA MARKETS LLC in account applications or
                  otherwise is true, correct and complete as of the date hereof, and Client will promptly
                  notify CAPITALIKA MARKETS LLC LLC of any changes in such information.</p>
                  <p  className={'contractsubTitles'}>18. DISCLOSURE OF FINANCIAL INFORMATION</p>
                  Client represents and warrants to CAPITALIKA MARKETS LLC that the financial information disclosed to
                  CAPITALIKA MARKETS LLC in this document is an accurate representation of Client’s current financial
                  condition and that Client has very carefully considered the portion of the Client’s assets
                  which the Client considers to be risk capital available for investment in Contracts. Client
                  recognizes that risk capital is the amount of money Client is willing to put at risk and if lost
                  would not, in any way, change Client’s lifestyle. Client agrees to inform CAPITALIKA MARKETS LLC
                  immediately if Client’s financial condition changes in such a way as to reduce Client’s net
                  worth, liquid assets and/or risk capital.
                  <p  className={'contractsubTitles'}>19. SETTLEMENT REVIEW, WITHDRAWALS, ACCOUNT CLOSURE</p>
                  Upon receipt of a request to withdraw funds from a CAPITALIKA MARKETS LLC trading account,
                  including withdrawals as a result of a request to close an account, CAPITALIKA MARKETS LLC is
                  authorized to perform a review of all account activity to ensure trades were settled
                  properly. Improperly settled trades shall include, but not be limited to, trades that were
                  executed: during platform or related hardware malfunctions; trades executed at off-market
                  pricing rates regardless how such pricing was obtained and including bad ticks and trades
                  executed during extreme market conditions, including acts of gods or similarly executed
                  trades completed during abnormal market conditions. If CAPITALIKA MARKETS LLC, in its sole
                  discretion, determines that any trade(s) were settled improperly CAPITALIKA MARKETS LLC is
                  authorized to retroactively cancel such improperly settled transactions and adjust account
                  equity based off of such cancellations prior to completing any withdrawal. If, during such a
                  settlement review, CAPITALIKA MARKETS LLC is authorized to cancel any applicable executed trades
                  and adjust client equity prior to completing any such withdrawals or collect uncharged
                  overnight interest, swaps prior to completing any withdrawals should it determine, in its
                  sole discretion, that trades completed within such an account: violate any agreed upon
                  term, condition, warranty or representation herein, including but not limited to those of
                  Section 6 and Section 8; Client has utilized such a trading account to defraud CAPITALIKA MARKETS 
                  LLC of promotional bonus funds by mirroring all activity therein with an offsetting
                  secondary trading account, regardless of what name(s) such secondary trading account is
                  held in; or Client account has been credited commissions earned from excessive churning
                  of third party accounts of which Client has been granted trading authorization through a
                  limited power of attorney.
                  <p  className={'contractsubTitles'}>20. JOINT ACCOUNTS</p>
                  If this account is held by more than one person, all of the joint and beneficial holders are
                  jointly and severally liable to CAPITALIKA MARKETS LLC for any and all obligations arising out of all
                  transactions in the account and each agree to be bound by all the terms and conditions of
                  this Agreement. In addition, each person named on this account has authority:
                  <p className={'contractClausules'}>A. to trade for the account subject to this Agreement,</p>
                  <p className={'contractClausules'}>B. to receive all correspondence and documents in respect to the account,</p>
                  <p className={'contractClausules'}>C. to receive or withdraw money from the account,</p>
                  <p className={'contractClausules'}>D. to execute agreements relating to the account, and</p>
                  <p className={'contractClausules'}>E. to deal with CAPITALIKA MARKETS LLC fully.</p>
                  CAPITALIKA MARKETS LLC, nevertheless, has the authority to require joint action by the parties to the
                  account in matters relating to the account. CAPITALIKA MARKETS LLC has possession over the
                  collateral in the account, whether owned by the holders individually or jointly. If a death
                  occurs to one or more of the holders, CAPITALIKA MARKETS LLC shall be notified in writing and shown
                  proof of a death certificate. All expenses due at the date of notification shall be charged to
                  the account. Unless the Account Application Form for Joint Account states otherwise, then
                  each holder is presumed to have an equal share in the account.
                  <p  className={'contractsubTitles'}>21. NO WAIVER OR AMENDMENT</p>
                  No provision of this Agreement may be waived or amended unless the waiver or
                  amendment is in writing and signed by both Client and an authorized officer of CAPITALIKA MARKETS 
                  LLC. No waiver or amendment of this Agreement may be implied from any 4 course
                  of dealing between the parties or from any failure by CAPITALIKA MARKETS LLC or its agents to assert
                  its rights under this Agreement on any occasion or series of occasions. No oral
                  agreements or instructions to the contrary shall be recognized or enforceable. This
                  instrument and the attachments hereto embody the entire agreement of the parties,
                  superseding any and all prior written and oral agreements and there are no other terms,
                  conditions or obligations other than those contained herein.
                  <p  className={'contractsubTitles'}>22. GOVERNING LAW</p>
                  This Agreement and the rights and obligations of the parties hereto, and any judicial or
                  administrative action or proceeding or arbitration arising directly or indirectly hereunder, or
                  in connection withthe transactions contemplated hereby, whether brought by Client or CAPITALIKA MARKETS 
                  LLC, shall be governed by the laws of St. Vincent and the Grenadines, without
                  reference to its conflicts of laws principles.
                  <p  className={'contractsubTitles'}>23. JURISDICTION</p>
                  Any dispute, controversy or claim which may arise out of or in connection with this
                  Agreement, or the execution, breach, termination or invalidity thereof, shall be settled by
                  the International Commercial Arbitration Court at the Chamber of Commerce and Industry
                  of the St. Vincent and the Grenadines in accordance with its Rules. The language to be
                  used in the arbitral proceedings shall be English and the place of arbitration shall be St.
                  Vincent and the grenadines The arbitral tribunal shall consist of no more than three (3)
                  arbitrators and all proceedings shall be conducted and decision rendered based solely on
                  the submission of documents and other such materials. No oral hearings or oral
                  arguments shall be held. Client hereby waives the right to have such proceeding
                  transferred to another jurisdiction. No arbitration hereunder or relating to this Agreement,
                  shall be brought by Client more than one year after the date of the transaction giving rise
                  to the cause for arbitration regardless of when Client discovers the facts relating to the
                  cause for arbitration.
                  <p  className={'contractsubTitles'}>24. TERMINATION</p>
                  This Agreement shall continue in effect until termination and may be terminated by Client
                  at any time when Client has no obligations held by or owed to CAPITALIKA MARKETS LLC, upon the
                  actual receipt by CAPITALIKA MARKETS LLC at its office of a written notice of termination, or at any
                  time whatsoever by CAPITALIKA MARKETS LLC upon the transmittal of written notice of termination of
                  any obligations set out in this Agreement. Termination by Client shall not relieve Client of
                  the obligation to pay any deficit balance in the account.
                  <p  className={'contractsubTitles'}>25. INDEMNIFICATION</p>
                  Client agrees to indemnify and hold CAPITALIKA MARKETS LLC, its affiliates, subsidiaries, employees,
                  agents, successors and assigns harmless from and against any and all liabilities, losses,
                  damages, costs and expenses, including attorney’s fees, incurred by CAPITALIKA MARKETS LLC
                  arising out of Client’s failure to fully and timely perform Client’s agreements herein or
                  should any of Client’s representations and warranties to CAPITALIKA MARKETS LLC fail to be true and
                  correct. Client also agrees to pay promptly to CAPITALIKA MARKETS LLC all damages, costs and
                  expenses, including attorney’s fees, incurred by CAPITALIKA MARKETS LLC in the enforcement of any
                  of the provisions of this Agreement and any other agreements between CAPITALIKA MARKETS LLC
                  and Client.
                  <p  className={'contractsubTitles'}>26. NOTICES</p>
                  Client agrees and acknowledges that all notice requirements set forth therein, shall be
                  satisfied by CAPITALIKA MARKETS LLC through the sending of any notification to the email address
                  submitted with this Agreement by the Client.
                  <p  className={'contractsubTitles'}>27. RECORDED CONVERSATIONS</p>
                  Client agrees and acknowledges that all conversations may be recorded. Conversations
                  relating to the account(s) between Client and CAPITALIKA MARKETS LLC personnel may be
                  electronically recorded with or without the use of an automatic tone warning. Client further
                  agrees to the use of such recordings and transcripts thereof as evidence by either party in
                  connection with any dispute or proceeding that may arise involving Client or CAPITALIKA MARKETS 
                  LLC. Client understands that CAPITALIKA MARKETS LLC destroys such recordings at regular
                  intervals in accordance with CAPITALIKA MARKETS LLC established business procedures and hereby
                  consents to such destruction.
                  <p className={'contractBoldSub'}>SUPPLEMENTAL RISK DISCLOSURE</p>
                  For any Customer that:
                  <p className={'contractClausules'}>-Is retired</p>
                  <p className={'contractClausules'}>-Is unemployed</p>
                  <p className={'contractClausules'}>-Has an annual income of less than $25,000</p>
                  <p className={'contractClausules'}>-Has a net worth less than $25,000</p>
                  <p className={'contractClausules'}>-Has no prior investment experience</p>
                  <p className={'contractClausules'}>-Is over 65 years of age. </p>
                  Only you can make the determination of whether trading Contracts for Difference is
                  suitable for you. In light of the information that you have provided above, CAPITALIKA MARKETS LLC
                  requires that you carefully consider all of the risk associated with such investments. Many
                  but not all of these risks are outlined in the Risk Disclosure Statement of the Client
                  Agreement.<p></p>
                  If, in the past, the only forms of investing strategies that you have pursued have been
                  conservative in nature, then CAPITALIKA MARKETS LLC encourages you to perform additional due
                  diligence and research prior to making any investment. You must recognize that onlky risk
                  capital is suitable for such investing. If you have determined that trading is suitable for you,
                  then please execute the acknowledgement below.
                  <p className={'contractBoldSub'}>ACKNOWLEDGMENT</p>
                  I have carefully considered the financial risk involved in trading Contracts for Difference, I
                  am willing to assume such risks associated with this type of investment, and wish to
                  proceed with opening an account.
                  <p  className={'contractTitles'}>PRIVACY POLICY NOTICE</p>
                  CAPITALIKA MARKETS LLC (“CAPITALIKA MARKETS LLC”) believes that the privacy and security of
                  an individual’s personal financial information is important. Our most valued assets are our
                  relationships with our Customers, and, therefore, we take your privacy concerns very seriously.
                  We apply the following policies with respect to all of our customers who are individuals, even
                  though we are only required to apply them to individuals who use our services for personal,
                  family or household purposes.
                  <p  className={'contractsubTitles'}>1. INFORMATION COLLECTION</p>
                  To serve our customers better, CAPITALIKA MARKETS LLC collects certain financial information on our
                  customers. In the case of individuals, CAPITALIKA MARKETS LLC may collect nonpublic personal financial
                  information about you from the following sources:
                  <p className={'contractClausules'}>-Carefully read this agreement in its entirety and sign where applicable. The Client
                  Agreement, and all sub-parts, will be your contract with CAPITALIKA MARKETS LLC and will govern
                  the relationship between you and CAPITALIKA MARKETS LLC. This document also contains
                  important disclosures, relating to the risks associated with trading the products
                  available through CAPITALIKA MARKETS LLC. You MUST read these disclosures carefully. If you
                  have any questions please contact your CAPITALIKA MARKETS LLC representative.</p>
                  <p className={'contractClausules'}>-Transaction History : Information about your transactions with CAPITALIKA MARKETS LLC, with our
                  affiliate(s) and with nonaffiliated third parties in connection with opening or servicing
                  your account, such as bank references.</p>
                  <p className={'contractClausules'}>-Credit Information: Information from a consumer reporting agency.</p>
                  <p  className={'contractsubTitles'}>2. INFORMATION DISCLOSURE.</p>
                  CAPITALIKA MARKETS LLC does not disclose any of your nonpublic personal information to nonaffiliated
                  third parties except as permitted by law and except as permitted under the terms and
                  conditions agreed to in your Client Agreement. The law permits disclosure of your nonpublic
                  personal information to nonaffiliated third parties in certain situations generally with respect to
                  effecting, administering, enforcing, or servicing a transaction or financial product that you
                  request or authorize. From time to time, CAPITALIKA MARKETS LLC and our affiliate(s) may share your
                  nonpublic personal information with one another in order for each of us to better serve you as
                  our customer. The nonpublic personal information that we may disclose to one another as
                  affiliates includes, but is not limited to:
                  <p className={'contractClausules'}>-Application and Registration Information:Information that we receive from you on your
                  account application form and other account opening documents, such as account
                  name, mailing address, phone number(s), banking information, e-mail address, annual
                  income, net worth, social security number, etc.</p>
                  <p className={'contractClausules'}>-Transaction History : Information about your transactions with us or about your
                  transactions with nonaffiliated third parties that we receive in connection with opening
                  or servicing your account.</p>
                  <p className={'contractClausules'}>-Credit Information: Information we receive from a consumer reporting agency.</p>
                  <p  className={'contractsubTitles'}>3. DISCLOSURE OF FORMER CUSTOMER INFORMATION</p>
                  CAPITALIKA MARKETS LLC does not disclose any nonpublic personal information of former customers,
                  except as permitted by law. Thus, should you close your account and become an
                  inactive/former customer, we will adhere to the measures delineated in this privacy policy.
                  <p  className={'contractsubTitles'}>4. INFORMATION SAFEGUARDS</p>
                  CAPITALIKA MARKETS LLC maintains physical, electronic and procedural safeguards to guard your
                  nonpublic personal information. As a result, only authorized employees who need to know your
                  information in order to provide you with products or services will have access to your nonpublic
                  personal information. We restrict the access of authorized employees to nonpublic personal
                  information for business purposes only. These safeguards are used by all CAPITALIKA MARKETS LLC
                  affiliates.
                  <p  className={'contractsubTitles'}>5. ADVANCE NOTICE OF PROPOSED CHANGES</p>
                  If at any time in the future our privacy policy should change, we will give you advance notice of
                  the proposed policy change, before it is implemented, and will explain your legal right to opt out
                  of any such additional or changed disclosures.
                  <p  className={'contractTitles'}>CONSENT TO DELIVERY OF STATEMENTS BY ELECTRONIC MEDIA</p>
                  Client hereby consents to have Client’s trade confirmations and account information available
                  on the Internet in lieu of having such information delivered to Client via mail or email. The
                  Client may revoke this consent and request the delivery of statements via email or mail,
                  however, Client understands that such a request may result in an additional fee and/or charge.<p></p>
                  By receiving your account statements electronically, you agree to promptly review and
                  communicate to us any discrepancies on such statements. You acknowledge your statement is
                  deemed received when made available to you by CAPITALIKA MARKETS LLC, regardless of whether you
                  actually accessed the statement. This consent shall be effective until revoked by you in writing
                  and received by CAPITALIKA MARKETS LLC. By your signature below, you represent
                  that the delivery and execution of this consent has been duly authorized and are within your
                  powers.<p></p>
                  All account statements and other information transmitted electronically shall be conclusive and
                  binding upon you unless you object in writing or by electronic communication immediately upon
                  receipt thereof.
                  <p  className={'contractTitles'}>ONLINE SERVICES AND ELECTRONIC TRADING AGREEMENT</p>
                  Any person or organization accessing or attempting to access the online or electronic trading
                  services of CAPITALIKA MARKETS LLC or any affiliate of CAPITALIKA MARKETS LLC,
                  (referred to herein as “CAPITALIKA MARKETS LLC”) must first agree to the terms of this
                  agreement. Such services shall include all statement reviews, new account origination, internet
                  trading and electronic order entry and reports, market, trading and general information,
                  including quotes, charts, news, and systems information, all clearing and back office functions
                  and services, all software provided by CAPITALIKA MARKETS LLC to user (“software”), the CAPITALIKA MARKETS LLC
                  web site, as well as any other services that may be added from time to time (“collectively
                  referred to herein as “the systems”). This agreement shall apply to any person or organization
                  who accesses or attempts to access the systems, as well as any person or organization who
                  benefits from such use, including but not limited to, users who benefit from the use of the
                  systems by brokers acting on their behalf (“user”). All limitations of liability and disclaimers
                  contained herein shall apply to the systems regardless of whether or not the systems or any
                  part thereof, was developed or is serviced or supported by CAPITALIKA MARKETS LLC. Use of the systems
                  or user’s signed acknowledgment, indicates user’s unqualified acceptance of all of the terms if
                  this agreement. If user finds this agreement unacceptable, it shall not use the systems. CAPITALIKA MARKETS 
                  LLC is willing to provide the systems to user only if user agrees to be bound by the
                  following terms:
                  <p className={'contractClausules'}>1. Some of the information available on the Systems will be provided by CAPITALIKA MARKETS LLC,
                  and some will be provided by various independent sources (“Information Providers”).
                  User acknowledges that the accuracy, completeness, timeliness, and correct
                  sequencing of the information concerning User’s trading and account activity, the
                  quotes, market and trading news, charts, trading analysis and strategies, and other
                  information that may be added from time to time (collectively referred to as the
                  “Information”), is not guaranteed by either CAPITALIKA MARKETS LLC or the Information Providers.
                  User agrees that in no event shall CAPITALIKA MARKETS LLC, any of its affiliates, or the
                  Information Providers, have any liability for the accuracy, completeness, timeliness or
                  correct sequencing of the Information, or for any decision made or taken by User in
                  reliance upon the Information or the Systems, or for any interruption of any Information
                  provided by the Systems, or for any aspect of the Systems. In addition, some of the
                  Information may be supplied by exchanges through Information Providers, and this
                  material is for informational purposes only. The exchanges do not represent that the
                  Information selected for display is comprehensive, complete, certified or accurate; do
                  not intend to, and do not, in any country, directly or indirectly, solicit business or offer
                  any contract to any person through the medium of this Information; or accept any
                  responsibility or liability for enabling the user to link to another site on the World Wide
                  Web, for the contents of any other site, or for any consequence which results from
                  acting upon the contents of another site.</p>
                  <p className={'contractClausules'}>2. User understands that technical problems or other conditions may delay or prevent
                  User from entering or canceling an order on the Systems, or likewise may delay or
                  prevent CAPITALIKA MARKETS LLC from executing or clearing an order on the Systems. Neither
                  CAPITALIKA MARKETS LLC, nor any of its affiliates, shall be liable for, and User agrees not to hold
                  or seek to hold CAPITALIKA MARKETS LLC, or any of its affiliates, liable for, any technical problems,
                  Systems failures and malfunctions, communication line failures, equipment or software
                  failures and malfunctions, Systems access issues, Systems capacity issues, high
                  Internet traffic demand or other Internet related problems, security breaches, theft and
                  other unauthorized access, and any other similar computer problems and defects, as
                  well as severe weather, earthquakes, floods and strikes or other labor problems in
                  connection with the use or attempted use of the Systems. CAPITALIKA MARKETS LLC does not
                  represent, warrant or guarantee that User will be able to access or use the Systems at
                  times or locations of User’s choosing, or that CAPITALIKA MARKETS LLC will have adequate
                  capacity for the Systems as a whole or in any particular geographic location. CAPITALIKA MARKETS 
                  LLC does not represent, warrant or guarantee that the Systems will provide
                  uninterrupted and error free service. CAPITALIKA MARKETS LLC does not make any warranties or
                  guarantees, express or implied, with respect to the Systems or its content, including
                  without limitation, warranties of quiet enjoyment, no infringement, title, merchantability
                  or fitness for a particular purpose, and merchantability for computer problems and for
                  informational content. CAPITALIKA MARKETS LLC does not guarantee or warrant that the Systems
                  will be free from infection, viruses, worms, Trojan horses or other code that manifest
                  contaminating or destructive properties. Neither CAPITALIKA MARKETS LLC, nor any of its affiliates,
                  shall be liable to User for any loss, cost, damage or other injury, whether in contract or
                  tort, arising out of or caused in whole or in part by CAPITALIKA MARKETS LLC or User’s use of, or
                  reliance on, the Systems or its content, or in otherwise performing its obligations under
                  or in connection with this Agreement. In no event will CAPITALIKA MARKETS LLC, or any of its
                  affiliates, be liable to User or any third party for any punitive, consequential, incidental,
                  special, indirect (including lost profits and trading losses and damages) or similar
                  damages, even if advised of the possibility of such damage. If some jurisdictions do not
                  allow the exclusion or limitation of liability for certain damages, in such jurisdictions the
                  liability of CAPITALIKA MARKETS LLC shall be limited in accordance with this Agreement to the
                  extent permitted by law. CAPITALIKA MARKETS LLC reserves the right to suspend service and deny
                  access to the Systems without prior notice during scheduled or unscheduled Systems
                  maintenance or upgrading.</p>
                  <p className={'contractClausules'}>3. User acknowledges that all orders placed through the Systems are at User’s sole risk.
                  User further acknowledges that User’s orders may be sent directly to the trading floors
                  of the various exchanges (or to an electronic trading Systems, if applicable), that there
                  may be minimum equity requirements and limits set by each contract as to the
                  maximum number of allowable contracts for orders processed through the Systems,
                  that current limits are subject to change, that contingency orders may not be accepted,
                  and that the online direct order placement service may be suspended on a contract’s
                  last trading day. User understands that orders that it sends directly to the trading floors
                  or an electronic trading Systems may be reviewed by an order desk, and if User fails to
                  meet the requirements, User’s order may be refused. User further understands that it
                  may be restricted from use of or refused access to the Systems at any time, and that
                  CAPITALIKA MARKETS LLC reserves the right to require a margin deposit prior to the execution of
                  any order placed through the Systems, or as otherwise required by CAPITALIKA MARKETS LLC
                  margin policy. In the event that there is a restriction on User’s account or that User fails
                  to make a margin deposit as required, neither CAPITALIKA MARKETS LLC, nor any of its affiliates,
                  shall be responsible for any delay or failure to provide the Systems, including the ability
                  to execute an order.</p>
                  <p className={'contractClausules'}>4. Although the Systems may provide access to numerous recommendations about how
                  to invest and what to buy, none of these recommendations shall be deemed to be
                  endorsed by CAPITALIKA MARKETS LLC. CAPITALIKA MARKETS LLC does not recommend any investment
                  advisory service or product, nor does CAPITALIKA MARKETS LLC offer aCy advice regarding the
                  nature, potential value, or suitability of any particular transaction or investment strategy.</p>
                  NOTHING IN THIS AGREEMENT SHOULD BE CONSTRUED AS A SOLICITATION
                  OR RECOMMENDATION TO BUY OR SELL ANY INSTRUMENT OR ENGAGE IN
                  ANY TRANSACTION.
                  <p className={'contractClausules'}>5</p>
                  <p className={'contractsubClausules'}>a. Upon approval of User’s account, CAPITALIKA MARKETS LLC will provide User with an
                  individual password and user identification code (“Access Codes”). The Access
                  Codes are intended to enable User to access User’s account and to enter buy
                  and sell orders for User’s account through the Systems, and therefore, User
                  must maintain the confidentiality, and prevent the unauthorized use, of the
                  Access Codes at all times. User accepts full responsibility for the use and
                  protection of the Access Codes, which includes, but is not limited to, all orders
                  entered into the Systems using the Access Codes and changes in User
                  account information that are entered through use of the Access Codes. User
                  hereby authorizes CAPITALIKA MARKETS LLC and any party claiming through CAPITALIKA MARKETS 
                  LLC to rely upon any information or instructions set forth in any data
                  transmission using the assigned Access Codes, without making further
                  investigation or inquiry, and regardless of the actual identity of the individual
                  transmitting the same, in connection with the operation of the Systems.</p>
                  <p className={'contractsubClausules'}>b. User accepts full responsibility for monitoring User’s account(s) with CAPITALIKA MARKETS 
                  LLC. In the event that User becomes aware of any loss, theft or
                  unauthorized use of User’s Access Codes, User shall notify CAPITALIKA MARKETS LLC
                  IMMEDIATELY by email to contacto@CAPITALIKA MARKETSx.com.</p>
                  <p className={'contractsubClausules'}>c. CAPITALIKA MARKETS LLC grants to User, and User accepts from CAPITALIKA MARKETS LLC, a
                  nonexclusive and non-transferable license to use the Systems solely for the
                  purposes provided herein and subject to any other agreements in effect
                  between User and CAPITALIKA MARKETS LLC. User agrees that it shall take reasonable
                  steps to protect, and shall not use, publish or disseminate, the information
                  made available or accessible through the use of the Systems, including without
                  limitation the Information, Access Codes, and Software, using methods at least
                  substantially equivalent to the steps it takes to protect its own proprietary
                  information, but not less than a reasonable standard, during the term of this
                  Agreement and for a period of five (5) years following the expiration,
                  termination, discontinuance or cancellation of this Agreement and shall prevent
                  the duplication or disclosure of such information, other than by or to its
                  employees who must have access to the information to perform User’s
                  obligations hereunder, provided that User shall make such employees aware of
                  the restrictions of this Section 5 and provided that User shall be solely
                  responsible for such information. Any and all materials that CAPITALIKA MARKETS LLC
                  provides to User in connection with the Systems, including without limitation all
                  Information, Access Codes, and Software, together with all modifications and
                  revisions thereto, and all copyrights, trademarks, patents, trade secret rights
                  and other intellectual and proprietary rights, title and interest relating thereto
                  are the property of CAPITALIKA MARKETS LLC, CAPITALIKA MARKETS LLC licensor’s, its successors
                  and assigns, the Information Providers, or other third parties, and are intended
                  for User’s sole and individual use. User shall not reproduce, modify, prepare
                  derivative works of, retransmit, disseminate, sell or distribute such materials in
                  any manner without the express written consent of both CAPITALIKA MARKETS LLC and the
                  relevant owner. In addition, User shall not permit any other person access to
                  the Systems. User further agrees not to delete any copyright notices or other
                  indications of protected intellectual property rights from materials that User
                  prints or downloads from the Systems. All such materials are provided “AS IS”,
                  without any warranty of any kind, whether express or implied, including
                  warranties of merchantability, fitness for a particular purpose, non infringement
                  and title.</p>
                  <p className={'contractsubClausules'}>d. User agrees that its use of the Systems is at User’s own risk. User shall be
                  responsible for providing and maintaining the means by which it accesses the
                  Systems, which may include, without limitation, personal computer, modem and
                  telephone, or other access line. User shall be responsible for all access and
                  services fees necessary to connect to the Systems and assumes all charges
                  incurred in accessing the Systems. User further assumes all risks associated
                  with the use and storage of information on User’s personal computer.</p>
                  <p className={'contractsubClausules'}>e. User hereby assumes sole responsibility and liability for the accuracy and
                  adequacy of information entered in connection with the use of the Systems.
                  User shall indemnify and hold harmless CAPITALIKA MARKETS LLC and its affiliates from
                  any and all consequential, incidental, special, punitive, or indirect damages
                  (including lost profits, trading losses and damages) resulting from, arising out of
                  or relating to such information, whether authorized or unauthorized. In the
                  event any inaccurate, incomplete or incorrect information relating to User is
                  communicated to CAPITALIKA MARKETS LLC, whether or not through the Systems, or in the
                  event the User determines that the Systems includes inaccurate, incomplete or
                  incorrect information relating to User, User covenants that it shall notify CAPITALIKA MARKETS 
                  LLC IMMEDIATELY by email to contacto@CAPITALIKA MARKETSx.com User also covenants
                  that it shall notify CAPITALIKA MARKETS LLC IMMEDIATELY, as provided herein, if</p>
                  <p className={'contractsubSubClausules'}>i. an order has been placed through the Systems and User has not received an order number;</p>
                  <p className={'contractsubSubClausules'}>ii. an order has been placed through the Systems and User has not
                  received an accurate acknowledgment (whether through hard copy,
                  electronic, or oral means) of the order or of its execution; or</p>
                  <p className={'contractsubSubClausules'}>iii. User has received acknowledgment (whether through hard copy,
                  electronic or oral means) of an execution for any order which User did
                  not place; or any other conflict similar to those stated herein. User
                  agrees and acknowledges that CAPITALIKA MARKETS LLC shall be entitled to rely
                  upon any oral, written or electronic communications, whether in tangible
                  or intangible form, received from User, including from any of User’s
                  officers, partners, principals or employees. </p>
                  <p className={'contractsubClausules'}>f. User hereby covenants and agrees thatUser:</p>
                  <p className={'contractClausules'}>6. Shall use the Systems only for the purpose of placing orders for currency contracts and
                  precious metals contracts, receiving fills and for receiving on-line User statement
                  reviews and new User account origination. In addition, User shall not directly or
                  indirectly transact any business using the Systems with any of its affiliates; including,
                  without limitation, accepting or countering any offer made by any of its affiliates; and in
                  connection therewith, User agrees that CAPITALIKA MARKETS LLC may take actions, determined in
                  its sole discretion, to prevent such transactions from occurring (but no such actions by
                  CAPITALIKA MARKETS LLC shall relieve User of its obligations as described in this sentence);</p>
                  <p className={'contractClausules'}>7. Shall perform the obligations arising in connection with any transaction completed
                  using the Systems, in accordance with the terms and conditions of such completed
                  transaction.</p>
                  <p className={'contractsubClausules'}>a. User agrees to indemnify and hold CAPITALIKA MARKETS LLC, its affiliates, and the
                  Information Providers harmless from and against any and all claims, demands,
                  proceedings, suits and actions, as well as all losses, liabilities, damages, costs
                  and expenses (including but not limited to attorneys’ fees) suffered by CAPITALIKA MARKETS 
                  LLC, resulting from or relating to any claims, proceedings, suits and
                  actions against CAPITALIKA MARKETS LLC arising from User’s violation of this Agreement
                  or any third party’s rights, including but not limited to copyright, proprietary and
                  privacy rights. CAPITALIKA MARKETS LLC shall have the exclusive right to defend, settle or
                  compromise any claim or demand instituted by any third party against CAPITALIKA MARKETS 
                  LLC, or against CAPITALIKA MARKETS LLC and User, arising out of User’s breach of
                  this Agreement. User hereby waives any and all rights User may have
                  independently to defend, settle or compromise any such claims or demands
                  and agrees to cooperate to the best of User’s ability with CAPITALIKA MARKETS LLC with
                  respect thereto, but CAPITALIKA MARKETS LLC may, in its sole discretion, authorize and
                  require User to defend, settle or compromise any such claim or demand as it
                  deems to be appropriate at User’s cost, expense and liability. This
                  indemnification and hold-harmless obligation shall survive the termination of
                  this Agreement.</p>
                  <p className={'contractsubClausules'}>b. CAPITALIKA MARKETS LLC reserves the right to terminate User’s access to the Systems in
                  its sole discretion, without notice and without limitation, for any reason
                  whatsoever, including but not limited to, the unauthorized use of User’s Access
                  Codes and/or account number(s), breach of this Agreement, or breach or
                  termination of any agreements User has entered into with . Upon termination,
                  cancellation or discontinuance of this Agreement, all rights granted in this
                  Agreement will terminate immediately and revert back to , and User shall
                  discontinue use of the Systems, and if applicable, shall return or destroy, as
                  requested by , all software (including all copies thereof).</p>
                  <p className={'contractsubClausules'}>c. User agrees to pay all subscription, service, and user fees, if any, and
                  commissions, for any orders executed through the Systems, and agrees that
                  such fees may be changed without notice. User agrees to pay all costs
                  (including attorneys’ fees), if any, incurred by in collecting overdue fees from
                  User.</p>
                  <p className={'contractsubClausules'}>d. This Agreement is made in User’s personal capacity and not on behalf of any
                  firm, corporation or other entity, unless User’s account is designated as such.
                  User agrees to use the Information solely in connection with User’s investment
                  activities and not in connection with any trade or business activities.</p>
                  <p className={'contractsubClausules'}>e. All express or implied conditions, warranties or undertakings, whether oral or in
                  writing, in law or in fact, including warranties as to satisfactory quality and
                  fitness for a particular purpose, regarding the Information or any aspect of the
                  Systems (including but not limited to Information access and order execution),
                  are excluded to the extent permitted by law.</p>
                  <p className={'contractsubClausules'}>f. User agrees that may hold and process, by computer or otherwise, any
                  information it obtains pertaining to User as a result of User’s use of the
                  Systems (“Personal Data”), and CAPITALIKA MARKETS LLC may access and use such information for
                  operational purposes, credit assessment, statistical, including behavioral
                  analysis, and to identify and provide User with information concerning products
                  and services (including those supplied by third parties) which may be of interest
                  to User or . User agrees that CAPITALIKA MARKETS LLC may disclose Personal Data to licensed credit
                  reference agencies and to any of subcontractors, agents or Information
                  Providers where necessary to provide User with the Service, or in the event
                  that CAPITALIKA MARKETS LLC has the right or duty to disclose or is otherwise permitted or compelled to
                  do so by law. Personal Data will be deleted from the Systems as soon as is
                  reasonably possible after User ceases to use the Systems, subject to
                  applicable record keeping requirements. User’s Personal Data may be
                  electronically transmitted or transferred throughout the world. User may inspect
                  its Personal Data, and have incomplete or inaccurate information rectified.
                  These companies acting on our behalf are required to keep your personal
                  information confidential.</p>
                  By either signing this Agreement, or using the Systems, User represents that it has read and
                  understands the foregoing terms and conditions, and that User agrees to be bound by them.
                  This Agreement supple-ments any other agreements User has entered into with CAPITALIKA MARKETS 
                  LLC and disputes hereunder are subject to the terms of the Arbitration Agreement, if
                  executed by User. If any provision of this Agreement is invalid or unenforceable under
                  applicable law, such provision shall be, to that extent, deemed omitted, and the remaining
                  provisions shall continue in full force and effect. This Agreement and performance hereunder
                  will be governed by and construed in accordance with St. Vincent and the Grenadines law. The
                  terms and conditions of this Agreement shall remain in full force and effect and shall survive
                  termination, discontinuance or cancellation of this Agreement. CAPITALIKA MARKETS LLC may modify the
                  Systems or change the terms of this Agreement, in whole or in part, upon notice through the
                  Systems, electronically and/or in writing.
                  <p  className={'contractTitles'}>TERMS OF BUSINESS FOR FOREIGN EXCHANGE TRANSACTIONS</p>
                  These terms and conditions set out the basis on which CAPITALIKA MARKETS LLC (“CAPITALIKA MARKETS 
                  LLC” or us) will enter into spot and forward foreign exchange transactions
                  (“Transactions”) with you (the “Client”) for delivery of currencies by electronic transfer.<p></p>
                  General Information:<p></p>
                  CAPITALIKA MARKETS LLC, LLC is incorporated in St. Vincent and the Grenadines (company number:
                  1875LLC2022) and is established at February 10th, 2022; Beachmont, Kingstown, St. Vincent
                  and the grenadines.<p></p>
                  We are registered as a Dealing In securities (licensing) Act (CAP.70) with the company number
                  1875LLC2022 and are supervised by St. Vincent and the Grenadines Financial Services
                  Authority.
                  <p className={'contractsubClausules'}>1. INTERPRETATION</p>
                  The definitions and rules of interpretation in this clause apply in these terms and conditions as
                  follow:<p></p>
                  «Account» means the bank account notified by you to us into which any sums due from us to
                  you pursuant to any Order will be paid.<p></p>
                  «Business Day» means a day when the clearing banks in the City of London are open for
                  business, excluding Saturday, Sunday and public holidays.<p></p>
                  «Client Money» means money held by CAPITALIKA MARKETS LLC LLC on your behalf and
                  which money will be held in a designated Client account, segregated from CAPITALIKA MARKETS LLC
                  money and will not be used by CAPITALIKA MARKETS LLC in the course of its business save for as
                  specifically set out in these terms and conditions.<p></p>
                  «Client Nominated Account» means the bank account nominated by us from time to time into
                  which the Sale Currency will be paid.<p></p>
                  «Contract» means the individual Orders which have been accepted by us.<p></p>
                  «Contract Note» means our written document setting out the details of an Order that will be
                  sent to you following Order Confirmation.<p></p>
                  «Forward Trade» means a transaction where the Value Date is later than five Business Days
                  after the instruction.<p></p>
                  «Good-Till-Cancelled» (GTC) specifies a limit order that stays on our books until executed.<p></p>
                  «Margin» means the deposit or advance payment required by us from you in advance of each
                  Forward Trade.<p></p>
                  «Margin Call» means request by us to you to provide additional amounts (not exceeding the
                  full amount of the Sale Currency) as we may reasonably require on account to cover adverse
                  exchange rate movements between the date of the Contract and the Value Date.<p></p>
                  «Margin Nominated Account» means the bank account nominated by us from time to time
                  into which the Margin will be paid.<p></p>
                  «Order» means the Client’s oral, electronic or written instruction for us to perform a Trade on
                  your behalf.<p></p>
                  «Order Confirmation» occurs when we confirm the details of the Order to you by telephone or
                  electronically or in writing.<p></p>
                  «Sale Currency» means the sums in a designated currency payable by you to us in respect of
                  a Trade including, without limitation, any Margin or Margin Call.<p></p>
                  «Spot Trade» means a transaction where the purchase or sale of a foreign currency, financial instrument, or commodity for instant delivery on a specified spot date.<p></p>
                  «Stop Loss order» means an order that becomes a firm Order if and when a specified
                  currency sells at or below the specified stop price.<p></p>
                  «Regulations» means the Payment Services Regulations<p></p>
                  «Trade» means each transaction to purchase and deliver currency for you.<p></p>
                  «Value Date» means the date of delivery to you of the currency purchased by you. Value Date
                  is five Business Days after the date of instruction<p></p>
                  «Website» means the website identified in clause 3.5 below, owned and managed by CAPITALIKA MARKETS 
                  LLC, LLC through which a Client will be able to access the Online System and the services
                  <p className={'contractsubClausules'}>2. SERVICES</p>
                  2.1 We will provide facilities for you to buy and sell currency.<p></p>
                  2.2 Once a Contract has been entered into, and whether or not a Contract Note has been
                  issued, it cannot be altered, cancelled or rescinded.<p></p>
                  2.3 When making an Order or entering into any Contract you rely solely on your own
                  judgment. If we provide you with information concerning any matter including (without limit) the
                  foreign exchange markets, it is on a voluntary basis and we do not accept responsibility for the
                  accuracy or completeness of such information or assume any duty of care in relation to it.<p></p>
                  2.4 We may at our absolute discretion refuse any Order without giving any reason and
                  without liability for any loss or damage incurred by you or any other party.<p></p>
                  2.5 We will not transfer to you any funds representing the benefit of any fluctuation in
                  currency arising after a Contract has been entered into as this may be outside commercial
                  purposes and, if so, unlawful.<p></p>
                  2.6 In entering into a Contract under these terms and conditions, you understand that:<p></p>
                  2.6.1 late arrival of funds may result in next day or deferred delivery;<p></p>
                  2.6.2 any forward contracts will be subject to daily margin requirement;<p></p>
                  2.6.3 we will execute GTC or OCO Orders on your instructions;<p></p>
                  2.6.4 we cannot predict future exchange rates;<p></p>
                  2.6.5 we will only accept payment directly from Client authorized and approved bank accounts
                  unless otherwise specified and agreed with us.<p></p>
                  2.7 We do not offer advice under these terms and conditions on any matter including
                  (without limit) the merits or otherwise of any currency transactions, on taxation, or markets.
                  <p className={'contractsubClausules'}>3. ONLINE SERVICES</p>
                  <p className={'contractBoldSub'}>General</p>
                  3.1 This aspect of the Service is available to Clients wanting to trade on the Electronic
                  Platform through our Website (the “Online System») or such other platform) we may elect for
                  our clients to use as notified in writing to you from time to time.<p></p>
                  3.2 This Service may only be used by persons aged 18 years and older.<p></p>
                  3.3 Upon CAPITALIKA MARKETS LLC’s approval of your request to access this Service, CAPITALIKA MARKETS LLC,
                  LLC grants to you (and your authorized users) a non-exclusive non-transferable licence to use
                  the Online System to access the Services.<p></p>
                  3.4 You acknowledge that due to the nature of the internet and electronic communication,
                  there is a risk that communications may not operate free from error or interruption. We shall not
                  be liable for:<p></p>
                  3.4.1 any error or interruption in communications; or<p></p>
                  3.4.2 for any losses or delays in the transmission of instructions to the Service caused by any
                  ISP or software failure or<p></p>
                  3.4.3 for any breaches of security of the Service beyond our reasonable control
                  <p className={'contractBoldSub'}>Access and security</p>
                  3.5 Access to the Online System via https://www.CAPITALIKA MARKETSx.com/ is permitted on a temporary
                  basis, and we reserve the right to withdraw or amend the service we provide on our Website
                  without notice. We will not be liable if for any reason the Online System is unavailable at any
                  time or for any period.<p></p>
                  3.6 From time to time, we may restrict access to some parts of our Website, or our entire
                  Website and therefore to the Online System, to users who have registered with us.<p></p>
                  3.7 If you choose, or you are provided with, a user identification code, password or any
                  other piece of information as part of our security procedures in accessing the Online System,
                  you must treat such information as confidential, and you must not disclose it to any third party.
                  You will also take all reasonable measures to ensure that your account is not accessed using
                  such information in an unauthorized manner. We have the right to disable any user
                  identification code or password, whether chosen by you or allocated by us, at any time, if in our
                  opinion you have failed to comply with any of the provisions of these terms and conditions.<p></p>
                  3.8 You are responsible for making all arrangements necessary for you to have access to
                  our Website. You are also responsible for ensuring that all persons who access our Website
                  and the Online System through your internet connection are aware of these terms, and that
                  they comply with them. Intellectual Property.
                  <p className={'contractBoldSub'}>Intellectual Property</p>
                  3.9 The Online System, our Website and the content therein and all intellectual property
                  rights pertaining thereto are owned by CAPITALIKA MARKETS LLC, LLC or licensed through third parties and all
                  rights, title and interest shall remain the property of CAPITALIKA MARKETS LLC, LLC or of such third parties.
                  <p className={'contractBoldSub'}>Transactions concluded through the Online System</p>
                  3.10 Contracts formed through our Website and the Online System or as a result of visits
                  made by you are governed by these terms and conditions.
                  <p className={'contractBoldSub'}>Our Website changes regularly</p>
                  3.11 We aim to update our Website regularly, and may change the content at any time. If the
                  need arises, we may suspend access to our Website, or close it indefinitely. Any of the material
                  on our Website may be out of date at any given time, and we are under no obligation to update
                  such material.<p></p>
                  3.12 The material displayed on our Website is provided without any guarantees, conditions
                  or warranties as to its accuracy.
                  <p className={'contractBoldSub'}>Viruses, hacking and other offences</p>
                  3.13 You must not misuse our Website by knowingly introducing viruses, trojans, worms,
                  logic bombs or other material which is malicious or technologically harmful. You must not
                  attempt to gain unauthorized access to our Website, the server on which our Website is stored
                  or any server, computer or database connected to our Website. You must not attack our
                  Website via a denial-of-service attack or a distributed denial-of service attack.<p></p>
                  3.14 By breaching this provision, you would commit a criminal offence under the Computer
                  Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities
                  and we will co-operate with those authorities by disclosing your identity to them. In the event of
                  such a breach, your right to use our Website will cease immediately.<p></p>
                  3.15 We will not be liable for any loss or damage caused by a distributed denial-of-service
                  attack, viruses or other technologically harmful material that may infect your computer
                  equipment, computer programs, data or other proprietary material due to your use of our
                  Website or to your downloading of any material posted on it, or on any Website linked to it.
                  <p className={'contractBoldSub'}>Information about you and your visits to our Website</p>
                  3.16 We process information about you in accordance with our privacy policy as set out on
                  our Website. By using our Website, you consent to such processing and you warrant that all
                  data provided by you is accurate.<p></p>
                  3.17 We may obtain information about your general internet usage by using a cookie file
                  which is stored on the hard drive of your computer. Cookies contain information that is
                  transferred to your computer’s hard drive. They help us to improve our site and to deliver a
                  better and more personalized service. By using our Website you consent to the use of Cookies.<p></p>
                  3.18 You may refuse to accept cookies by activating the setting on your browser which
                  allows you to refuse the setting of cookies. However, if you select this setting you may be
                  unable to access certain parts of our site. Unless you have adjusted your browser setting so
                  that it will refuse cookies, our system will issue cookies when you log on to our site.
                  <p className={'contractBoldSub'}>Linking to our Website</p>
                  3.19 You may link to our home page, provided you do so in a way that is fair and legal and
                  does not damage our reputation or take advantage of it, but you must not establish a link in
                  such a way as to suggest any form of association, approval or endorsement on our part where
                  none exists.<p></p>
                  3.20 You must not establish a link from any Website that is not owned by you.<p></p>
                  3.21 Our Website must not be framed on any other site, nor may you create a link to any
                  part of our site other than the home page. We reserve the right to withdraw linking permission
                  without notice. The Website from which you are linking must comply in all respects with the
                  content standards set out in our acceptable use policy as set out on our Website.
                  <p className={'contractsubClausules'}>4. INSTRUCTIONS TO PLACE ORDERS</p>
                  <p className={'contractsubSubClausules'}>a. You may give us instructions to place Orders, orally, electronically or in writing.</p>
                  <p className={'contractsubSubClausules'}>b. The only persons authorized to give us instructions on your behalf are those
                  notified in writing by you to us, as varied by written notice to us, we shall not be
                  bound by any such variation until we have received it and reserve the right to
                  seek confirmation of that Order before being bound by it where we deem this
                  appropriate.</p>
                  <p className={'contractsubClausules'}>5. DELIVERY AND PAYMENTS</p>
                  <p className={'contractsubSubClausules'}>a. In the event of a Spot Trade, you will pay the Sale Currency in full into the
                  Client Nominated Account for value no later than the close of business the
                  following business day.</p>
                  <p className={'contractsubSubClausules'}>b. In the event of a Forward Trade, you will immediately (in the normal course of
                  events within 24 hours after the Order is placed unless otherwise agreed) pay
                  into the Margin Nominated Account in cleared funds a Margin as determine in
                  accordance with clause 6.2 below or such other percentage as we may specify
                  at our sole discretion and will pay any outstanding balance of the Sale
                  Currency into the Nominated Account not later than one Business Day before
                  the Value Date of the particular Forward Trade.</p>
                  <p className={'contractsubSubClausules'}>c. CAPITALIKA MARKETS LLC, LLC accepts no responsibility in the event that the Client sends
                  currency to the incorrect Client segregated designated currency account.</p>
                  <p className={'contractsubSubClausules'}>d. All payments made by you under these terms and conditions shall be free from
                  and without set-off, withholding, counterclaim or deduction whatsoever. We
                  shall make all payments due to you under these terms and conditions in full
                  unless required by law to make deductions including, without limitation,
                  deductions or withholdings in respect of any taxation liabilities.</p>
                  <p className={'contractsubSubClausules'}>e. Banks have specified cut off times for the receipt and dispatch of electronic
                  payments. We accept no responsibility for any consequence attributable to the
                  arrival of funds or instruction of payment after the relevant cut off times.</p>
                  <p className={'contractsubSubClausules'}>f. All funds provided by you under a Contract (whether as security or otherwise)
                  may be appropriated by us if we incur any liability in respect of any Trade or in
                  the event that you are unable to pay sums due to us or breach of these terms
                  and conditions.</p>
                  <p className={'contractsubSubClausules'}>g. If you fail to make any payments due to us then we may charge you interest at
                  the rate set out in the Late Payment of Commercial Debts (Interest) Act 1998
                  and/or any regulations or guidance issued under that Act.</p>
                  <p className={'contractsubSubClausules'}>h. CAPITALIKA MARKETS LLC, LLC may, at its discretion, make payments to third party introducer’s.</p>
                  <p className={'contractsubSubClausules'}>i. We will not be obliged to pay you interest on any Margin or Client Money held by us on your behalf.</p>
                  <p className={'contractsubClausules'}>6. MARGIN</p>
                  <p className={'contractsubSubClausules'}>a. We may in our absolute discretion, at any time before or after we agree to enter
                  into a Contract, as set out in clause 5.2 above, require you to provide us with
                  Margin. You acknowledge that this money will not generally be covered by the
                  Client Money rules and agree that the principles set out in this clause 6 will
                  apply.</p>
                  <p className={'contractsubSubClausules'}>b. In assessing the sum to be requested as Margin we will, acting reasonably, use
                  our discretion in making a determination of the risk of the trade and request an
                  appropriate sum, given the risk determination, as margin for the trade.</p>
                  <p className={'contractsubSubClausules'}>c. We will hold the Margin on trust for you in a designated Client account. This
                  means that you are still the beneficial owner of this money until such point in
                  time as we incur any costs, losses or liabilities in connection with or arising out
                  of your Contract(s) including, without limit, liabilities we incur with third party
                  currency dealers, exchanges, brokers, banks or similar entities. Then we will
                  become the beneficial owner of that proportion of the money, held as Margin,
                  equal to our costs, losses, or liabilities, without notice or demand by us.</p>
                  <p className={'contractsubSubClausules'}>d. We reserve the right to make a Margin Call to re-establish the Margin to the
                  original percentage level agreed for the particular Trade if the Margin
                  subsequently falls below an acceptable threshold for that Trade.</p>
                  <p className={'contractsubSubClausules'}>e. All Margin Calls must be paid within twenty four hours of our first communicating the Margin Call to you.</p>
                  <p className={'contractsubSubClausules'}>f. The Margin will vest automatically in us in any of the following circumstances:</p>
                  <p className={'contractsubsubSubClausules'}>i. on the Value Date;</p>
                  <p className={'contractsubsubSubClausules'}>ii. if you breach of any material term of these terms and conditions:</p>
                  <p className={'contractsubsubSubClausules'}>iii. in the circumstances set out in clause 7.1.5, below.</p>
                  <p className={'contractsubSubClausules'}>g. We may also offset any Margin(s) or other monies we are holding in respect of
                  any one of your Contracts, against any costs, losses and/or liabilities that we
                  incur in connection with or arising out of any other Contract you have with us.</p>
                  <p className={'contractsubClausules'}>7. DEFAULT AND TERMINATION</p>
                  <p className={'contractsubSubClausules'}>a. We may close out all or part of any Trade from the market and/or terminate any Contract without notice if:</p>
                  <p className={'contractsubsubSubClausules'}>i. you fail to make any payment for a Service when due in accordance with these terms and conditions:</p>
                  <p className={'contractsubsubSubClausules'}>ii. you are in material breach of these terms and conditions or fail to comply with your obligations in respect of a Trade:</p>
                  <p className={'contractsubsubSubClausules'}>iii. you are in breach of any relevant statute, regulation or regulatory regime;</p>
                  <p className={'contractsubsubSubClausules'}>iv. it becomes unlawful for us to perform the services;</p>
                  <p className={'contractsubsubSubClausules'}>v. you become unable to pay your debts as they become due or have a
                  bankruptcy petition presented against them or you propose a form of
                  composition or arrangement to its creditors or if you ceaseor threaten to
                  cease to carry on all or a part of your business; or</p>
                  <p className={'contractsubsubSubClausules'}>vi. any of the events specified above or anything analogous to them occurs under the laws of any applicable jurisdiction.</p>
                  <p className={'contractsubSubClausules'}>b. If you become aware of the occurrence of any event referred to in this clause 7, you must give us immediate written notice.</p>
                  <p className={'contractsubClausules'}>8. LIMITATION OF LIABILITY</p>
                  <p className={'contractsubSubClausules'}>a. The following provisions set out our entire liability to you for any losses, costs
                  (including legal costs), damages, expenses, taxes, charges or any other liability
                  whatsoever (“Claim”) except for:</p>
                  <p className={'contractsubsubSubClausules'}>i. death or personal injury caused by our negligence; or</p>
                  <p className={'contractsubsubSubClausules'}>ii. the negligence of those for whom we are legally responsible that causes death or personal injury; or</p>
                  <p className={'contractsubsubSubClausules'}>iii. for our fraudulent misrepresentation; for all of which liability is unlimited.</p>
                  <p className={'contractsubSubClausules'}>b. The limitation and exclusion of liability is set on the basis that you are aware of
                  the volatility of the foreign currency market.</p>
                  <p className={'contractsubSubClausules'}>c. You will be liable for any losses incurred in respect of unauthorized payment
                  Transactions arising from:</p>
                  <p className={'contractsubsubSubClausules'}>i. the use of a lost account payment instructions; or</p>
                  <p className={'contractsubsubSubClausules'}>ii. your failure to keep personalized security features of your account information.</p>
                  <p className={'contractsubSubClausules'}>d. We are not liable for any Claim that arises as a result of currency fluctuation
                  between the Order Confirmation and the Value Date, or as a result of your noncompliance with these terms and conditions or caused by the failure or delay of
                  any third party in the transmission, provision or delivery of any Service.</p>
                  <p className={'contractsubSubClausules'}>e. We are not liable for indirect loss, or for loss of profits, management time,
                  contracts, goodwill, anticipated savings, even if such loss was reasonably
                  foreseeable.</p>
                  <p className={'contractsubSubClausules'}>f. Subject to provisions of this clause 8, our total aggregate liability to you for any
                  Claim will not exceed the total Sale Currency paid by you in respect of the
                  Order giving rise to the Claim or where no Order is the basis for a claim,
                  £500,000.</p>
                  <p className={'contractsubSubClausules'}>g. You will indemnify and keep us indemnified against all losses (including legal
                  costs on a solicitor and own client basis) and liabilities incurred by us in the
                  proper performance of Services for you or in the enforcement of our rights
                  hereunder and, in particular, losses and liabilities incurred as a result of:</p>
                  <p className={'contractsubsubSubClausules'}>i. any default in payment by you of any sum due under a Contract or any
                  other breach of these terms and conditions;</p>
                  <p className={'contractsubsubSubClausules'}>ii. our carrying out your instructions: or</p>
                  <p className={'contractsubsubSubClausules'}>iii. our exercising our rights under clause 7.1 above, but in such event we
                  will have the right, as an alternative to our right to an indemnity, to setoff against any monies held by us on your account any amounts owed
                  by you to us.</p>
                  <p className={'contractsubClausules'}>9. WARRANTIES, REPRESENTATIONS AND UNDERTAKINGS</p>
                  <p className={'contractsubSubClausules'}>a. You warrant and represent to us on a continuing basis that:</p>
                  <p className={'contractsubsubSubClausules'}>i. all information that you supply to us Is complete, true, accurate and not
                  misleading in any material respect;</p>
                  <p className={'contractsubsubSubClausules'}>ii. all Orders are placed In pursuance of the Client’s usual trade or business;</p>
                  <p className={'contractsubsubSubClausules'}>iii. Orders will be for commercial purposes only and not for currency
                  speculation and not for investment business;</p>
                  <p className={'contractsubsubSubClausules'}>iv. you are acting as principal and not as another party’s agent or representative;</p>
                  <p className={'contractsubsubSubClausules'}>v. you are not prevented by any legal disability or subject to any law or
                  regulation that from performing your obligations under these terms and
                  conditions and any related transactions contemplated by them.</p>
                  <p className={'contractsubsubSubClausules'}>vi. you have all necessary consents and have the authority to enter into an
                  agreement under These term sand condition sand subsequent Orders
                  or other transaction contemplated by them (and if the Client is a body
                  corporate, you are properly empowered and have obtained all
                  necessary corporate or other authority pursuant to its constitutional and
                  organizational documents);</p>
                  <p className={'contractsubsubSubClausules'}>vii. you comply with all relevant laws, regulations, exchange control
                  requirements and registration requirements;</p>
                  <p className={'contractsubsubSubClausules'}>viii. all Orders are placed in the course of your usual trade or business and
                  will be for commercial or investment purposes only and not for currency
                  speculation; and</p>
                  <p className={'contractsubsubSubClausules'}>ix. you will take risk in and ownership of the purchased currency upon
                  payment of the full amount of the sold currency as directed by on your
                  instructions contained in the relevant order.</p>
                  <p className={'contractsubSubClausules'}>b. You undertake to inform us with immediate effect, if you are a corporation,
                  where beneficial ownership of your corporation changes by more than 10%.</p>
                  <p className={'contractsubClausules'}>10. REGULATIONS</p>
                  <p className={'contractsubSubClausules'}>a. Any money remittance transactions carried out by us are subject to the Regulations.</p>
                  <p className={'contractsubSubClausules'}>b. If you require an information on the Regulations or have any questions
                  regarding the impact of the Regulations on your rights and obligations under
                  these terms in relation to the Transactions, such questions should be
                  addressed directly to our compliance team.</p>
                  <p className={'contractsubClausules'}>11. TAPE RECORDING OF CONVERSATIONS AND RECORD KEEPING</p>
                  <p className={'contractsubSubClausules'}>You agree that we may record telephone conversations between you and us; and use
                  such recordings, or transcripts from such recordings, as evidence in any dispute or
                  anticipated dispute.</p>
                  <p className={'contractsubClausules'}>12. MONEY LAUNDERING REGULATIONS</p>
                  <p className={'contractsubSubClausules'}>a. To comply with the requirements of the Money Laundering and related
                  Regulations, it may be necessary to obtain from you, and retain, evidence of
                  your personal identity (or two directors of your company) in our records. If
                  satisfactory evidence is not provided we cannot accept your instructions.</p>
                  <p className={'contractsubSubClausules'}>b. We are also obliged to report any reasonable suspicions about instructions
                  received, transactions and activities to the regulatory authorities. This may
                  affect our relationship with you so far as confidentiality is concerned. If we are
                  required under legislation (including Money Laundering Regulations and The
                  Proceeds of Crime Act) to refrain from communicating with you and/or
                  proceeding with your instructions, we can accept no liability for the
                  consequences of being prevented from doing so.</p>
                  <p className={'contractsubClausules'}>13. DATA PROTECTION</p>
                  <p className={'contractsubSubClausules'}>a. In participating in Transactions under these terms and conditions you will be
                  providing us with personal information within the meaning of the Data
                  Protection Act 1998.</p>
                  <p className={'contractsubSubClausules'}>b. We are the data controller for purposes of the above mentioned data protection
                  legislation and any queries about the use of personal data by us should be
                  referred to our data compliance officer.</p>
                  <p className={'contractsubSubClausules'}>c. You consent to us processing all such information for the purposes of
                  performing the Services under these terms and conditions and also for the
                  purposes of administering the relationship between you and us.</p>
                  <p className={'contractsubSubClausules'}>d. We may use and/or disclose such information to any of our associates for sales
                  and marketing purposes unless you notify your objection to this in writing.</p>
                  <p className={'contractsubClausules'}>14. PROPRIETARY RIGHTS</p>
                  <p className={'contractsubSubClausules'}>a. The Client acknowledges and agrees that the CAPITALIKA MARKETS LLC, LLC and/or its
                  licensor’s own all intellectual property rights in the Services.</p>
                  <p className={'contractsubSubClausules'}>b. Except as expressly stated herein, these terms and conditions do not grant the
                  Client any rights to, or in, patents, copyrights, database rights, trade secrets,
                  trade names, trademarks (whether registered or unregistered), or any other
                  rights or licenses in respect of the Services.</p>
                  <p className={'contractsubSubClausules'}>c. The CAPITALIKA MARKETS LLC confirms that It has all the rights in relation to the Services
                  that are necessary to grant all the rights it purports to grant under, and in
                  accordance with, these terms and conditions.</p>
                  <p className={'contractsubClausules'}>15. CONFIDENTIALITY</p>
                  <p className={'contractsubSubClausules'}>a. Each party may be given access to Confidential Information from the other
                  party in order to perform its obligations under these terms and conditions. A
                  party’s Confidential Information shall not be deemed to include information that;</p>
                  <p className={'contractsubsubSubClausules'}>i. is or becomes publicly known other than through any act or omission of the receiving party;</p>
                  <p className={'contractsubsubSubClausules'}>ii. was in the other party’s lawful possession before the disclosure;</p>
                  <p className={'contractsubsubSubClausules'}>iii. is lawfully disclosed to the receiving party by a third party without restriction on disclosure;</p>
                  <p className={'contractsubsubSubClausules'}>iv. is independently developed by the receiving party, which independent development can be shown by written evidence; or</p>
                  <p className={'contractsubsubSubClausules'}>v. is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.</p>
                  <p className={'contractsubSubClausules'}>b. Each party shall hold the other’s Confidential Information in confidence and,
                  unless required by law, not make the other’s Confidential Information available
                  to any third party, or use the other’s Confidential Information for any purpose
                  other than for the carrying out of its obligations or enforcement of its rights
                  under these terms and conditions.</p>
                  <p className={'contractsubSubClausules'}>c. Each party shall take all reasonable steps to ensure that the other’s
                  Confidential Information to which it has access is not disclosed or distributed by
                  its employees or agents in violation of these terms and conditions.</p>
                  <p className={'contractsubSubClausules'}>d. Neither party shall be responsible for any loss, destruction, alteration or disclosure of Confidential Information caused by any third party.</p>
                  <p className={'contractsubSubClausules'}>e. This clause 15 shall survive termination of these terms and conditions between the CAPITALIKA MARKETS LLC, LLC and the Client, however arising.</p>
                  <p className={'contractsubClausules'}>16. GENERAL</p>
                  <p className={'contractsubSubClausules'}>a. Waiver. A waiver of any right under these terms and conditions is only effective
                  if it is in writing and it applies only to the party to whom the waiver is addressed
                  and to the circumstances for which it is given.</p>
                  <p className={'contractsubSubClausules'}>b. Unless specifically provided otherwise, rights arising under these terms and conditions are cumulative and do not exclude rights provided by law.</p>
                  <p className={'contractsubSubClausules'}>c. Force majeure. The CAPITALIKA MARKETS LLC, LLC shall have no liability to the Client under
                  these terms and conditions if it is prevented from or delayed in performing its
                  obligations under these terms and conditions, or from carrying on its business,
                  by acts, events, omissions or accidents beyond its reasonable control,
                  including, without limitation, strikes, lock-outs or other industrial disputes
                  (whether involving the workforce of the CAPITALIKA MARKETS LLC or any other party),
                  failure of a utility service or transport or telecommunications network, act of
                  God, war, riot, civil commotion, malicious damage, compliance with any law or
                  governmental order, rule, regulation or direction, accident, breakdown of plant
                  or machinery, fire, flood, storm or default of CAPITALIKA MARKETS LLC, LLC’s or subcontractors, provided that the Client is notified of such an event and its
                  expected duration.</p>
                  <p className={'contractsubSubClausules'}>d. Severance. If any provision (or part of a provision) of these terms and
                  conditions is found by any court or administrative body of competent jurisdiction
                  to be invalid, unenforceable or illegal, the other provisions shall remain in force.</p>
                  <p className={'contractsubSubClausules'}>e. If any invalid, unenforceable or illegal provision would be valid, enforceable or
                  legal if some part of it were deleted, the provision shall apply with whatever
                  modification is necessary to give effect to the commercial intention of the parties.</p>
                  <p className={'contractsubSubClausules'}>f. Entire Agreement. These terms and conditions, and any documents referred to
                  in them, constitute the whole agreement between the parties and supersede
                  any previous arrangement, understanding or agreement between them relating
                  to the subject matter they cover.</p>
                  <p className={'contractsubSubClausules'}>g. Each of the parties acknowledges and agrees that in entering into the
                  Agreement it does not rely on any undertaking, promise, assurance, statement,
                  representation, warranty or understanding (whether in writing or not) of any
                  person (whether party to these terms and conditions or not) relating to the
                  subject matter hereof, other than as expressly set out in these terms and
                  conditions.</p>
                  <p className={'contractsubSubClausules'}>h. Assignment. The Client shall not, without the prior written consent of CAPITALIKA MARKETS 
                  LLC, assign, transfer, charge, sub-contract or deal in any other manner
                  with all or any of its rights or obligations under these terms and conditions.</p>
                  <p className={'contractsubSubClausules'}>i. CAPITALIKA MARKETS LLC, LLC may at any time assign, transfer, charge, sub-contract or deal
                  in any other manner with all or any of its rights or obligations under these terms
                  and conditions.</p>
                  <p className={'contractsubSubClausules'}>j. No partnership or agency. Mottling in these terms and conditions is intended to
                  or shall operate to create a partnership between the parties, or authorize either
                  party to act as agent for the other, and neither party shall have the authority to
                  act in the name or on behalf of or otherwise to bind the other in any way
                  (including, but not limited to, the making of any representation or warranty, the
                  assumption of any obligation or liability and the exercise of any right or power).</p>
                  <p className={'contractsubSubClausules'}>k. Third party rights. These terms and conditions does not confer any rights on
                  any person or party (other than the parties to these terms and conditions and,
                  where applicable, their successors and permitted assigns) pursuant to the
                  Contracts (Rights of Third Parties) Act 1999.</p>
                  <p className={'contractsubSubClausules'}>l. Notices. Any notice required to be given under these terms and conditions shall
                  be in writing and shall be delivered by hand or sent by pre-paid first-class post
                  or recorded delivery post to the other party at its address set out in these terms
                  and conditions or the Order Form, or such other address as may have been
                  notified by that party for such purposes, or sent by fax to the other party’s fax
                  number as set out in these terms and conditions.</p>
                  <p className={'contractsubSubClausules'}>m. A notice delivered by hand shall be deemed to have been received when
                  delivered (or if delivery is not in business hours, at 9 am on the first business
                  day following delivery). A correctly addressed notice sent by pre-paid first-class
                  post or recorded delivery post shall be deemed to have been received at the
                  time at which it would have been delivered in the normal course of post, A
                  notice sent by fax shall be deemed to have been received at the time of
                  transmission (as shown by the timed printout obtained by the sender).</p>
                  <p className={'contractsubSubClausules'}>n. Governing law and jurisdiction. These terms and conditions and any disputes or
                  claims arising out of or in connection with it or its subject matter or formation
                  (including non-contractual disputes or claims) are governed by, and
                  construed in accordance with, the law of St. Vincent and the Grenadines.</p>
                  The parties irrevocably agree that the courts of England have exclusive jurisdiction to settle
                  any dispute or claim that arises out of or in connection with these terms and conditions or its
                  subject matter or formation (including non-contractual disputes or claims).<p></p>
                  Please sign using the same signature that’s on your identification (or as close as possible)
        </div>
    )
}
}
export default Contract;