const url_webapi = process.env.REACT_APP_API;
module.exports = {
    UploadDoc: function UploadDoc(document,idGntCte,hashEnvio,callback){ //Contrato1,idGntCte,hashEnvio,
        //Contrato1='Contrato1'
        fetch('https://wlcpkmvalidate.gntapi.com/api/contratos/cte/envio', {
            "method": "POST",
            "headers": {
                'Content-Type': 'application/json',
            },
            //"body": '{ "hashEnvio" : "EGUNYRXVJ64U", "idGntCte" : "1", "base64BinaryStr" : "'+ document +'" }'
            "body": '{ "hashEnvio" : "'+hashEnvio+'", "idgnt_whiteLevel_clientesInfo" : "'+idGntCte+'", "base64BinaryStr" : "'+ document +'" }'
        })
        .then((response) => response.json())
        .then((responseJson) => {
          callback(responseJson)
        })
        .catch((error) => {
          console.log(error);
        })
    },
    contractApprover:function contractApprover(idGnt, operacion, callback){
      fetch('https://wlcpkmvalidate.gntapi.com/api/contratos/ctes/info?idgnt_whiteLevel_clientesInfo='+idGnt+'&operacion='+operacion ,{
        "method":"GET",
      })
      .then((response) => response.json())
      .then((responseJson) => {
        callback(responseJson);
      })
      .catch((error) => {
        console.log(error);
      })
    },

    UploadDemoLiveDoc: function UploadDoc(document,idGntCte,hashEnvio,callback){ //Contrato1,idGntCte,hashEnvio,
      fetch('https://wlcpkmvalidate.gntapi.com/api/contratos/validacion/cte/envio', {
        "method": "POST",
        "headers": {
            'Content-Type': 'application/json',
        },
        "body": '{ "idgnt_validacion_email" : "'+idGntCte+'", "base64BinaryStr" : "'+ document +'" }'
    })
    .then((response) => response.json())
    .then((responseJson) => {
      callback(responseJson)
    })
    .catch((error) => {
      console.log(error);
    })
    },
}