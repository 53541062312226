import React, { Component } from 'react';
import moment from 'moment';

var fechacompleta = moment().format('LL');
class ibContract extends Component{
    
    constructor(props){
        super(props);
        this.state = {
          
        }
    }

    render(){
        return(
            <div>
                <p className={'contractBold'}>The present Introducing Agreement (the “Agreement”) is entered into as of the day {fechacompleta} between CAPITALIKA MARKETS LLC. (CAPITALIKA MARKETS LLC) and {this.props.name} (“Introducer”).</p>
                WHEREAS, CAPITALIKA MARKETS LLC is a dealer in Contracts For Difference on Equities, ETF, Futures and Forex on the aforementioned contracts (collectively, “CFD’s”);<p></p>
                WHEREAS, Introducer is engaged in the business of soliciting customers for transactions in CFD’s and wishes to introduce such customers to CAPITALIKA MARKETS LLC; and<p></p>
                WHEREAS, Introducer and CAPITALIKA MARKETS LLC wish to enter into an agreement pursuant to which Introducer will serve as CAPITALIKA MARKETS LLC referral source to identify and refer prospective, suitable counterparties to CAPITALIKA MARKETS LLC for the pur-pose of entering into transactions in CFD’s;<p></p>
                NOW, THEREFORE, in consideration of the premises and the covenants and representations contained herein, the parties agree as follows:
                <p className={'contractsubTitles'}>1. TERMS AND DEFINITIONS</p>
                A. “INTRODUCED COUNTERPARTY” or “INTRODUCED ACCOUNT” means each counterparty or account introduced to CAPITALIKA MARKETS LLC by introducer, accepted by CAPITALIKA MARKETS LLC and with whom CAPITALIKA MARKETS LLC does not have a current or prior existing cfd relationship.<p></p>
                B. “CONTRACT” is defined as a transaction amount of 1 share/unit<p></p>
                C. “BASE CURRENCY” is defined as the currency that is being priced, and the exchange rate is the price at which 1 unit of base currency can be bought or sold, and this price is expressed as x units of quoted currency,<p></p>
                D. “PIP” is defined asthe smallest numerical value of a price (the last digit to the right of the decimal point). “pips” is synonymous and interchangeable with the terms “points” and “ticks”.
                <p className={'contractsubTitles'}>2. INTRODUCING SERVICES</p>
                A. Introducer hereby agrees to serve as a referral source for the purpose of:<p></p>
                <p className={"contractClausules"}>(I) introducing and referring prospective counterparties to CAPITALIKA MARKETS LLC for the purposes of cfd trading, and</p>
                <p className={"contractClausules"}>(II) Assessing the qualifications of such prospective counterparties to engage in CFD trading. In determining whether a prospective counterparty is a qualified counterpart for purposes of cfd trading, introducer shall apply the counterparty qualification standards as established by CAPITALIKA MARKETS LLC and communicated to introducer from time to time. The counterparty qualification standards will include (but are not limited to) an assessment of the prospective counterparty’s credit, market knowledge and experience in CFD trading. Introducer shall provide all relevant information to CAPITALIKA MARKETS LLC with respect to introducer’s assessment of the qualifications of each prospective counterparty pursuant to CAPITALIKA MARKETS LLC. Introducer hereby agrees to use its best efforts during the term hereof to fulfill the aforementioned purposes.</p>
                B. CAPITALIKA MARKETS LLC will have no obligation to accept as counterparty any person referred to it by introducer.<p></p>
                C. In performing the services set forth herein, introducer will have no authority to bind CAPITALIKA MARKETS LLC in any way and will make no representations to prospective counterparties or other persons relating to CAPITALIKA MARKETS LLC or its cfd trading operations that are not expressly authorized by this agreement or have not been previously approved in writing by CAPITALIKA MARKETS LLC. Introducer shall not issue, publish or distribute any advertisement, market letter, market research report or other sales literature utilizing or making reference to the name or facilities of CAPITALIKA MARKETS LLC or any of its affiliates without CAPITALIKA MARKETS LLC’s prior written consent.<p></p>
                D. Introducer agrees to maintain all websites and other web based promotional materials in compliance with CAPITALIKA MARKETS LLC’s ib promotional material rules (the “rules”) that have been provided to introducer. All information contained therein, including proper disclaimers and statements regarding CAPITALIKA MARKETS LLC and its affiliates, must comply with the rules as set forth by CAPITALIKA MARKETS LLC. Any subsequent review of introducer’s web materials that reveal a failure to comply with the rules shall be deemed a breach of this agreement under 10(a) herein, and result in immediate termination.<p></p>
                E. Introducer will carry on its business as an independent contractor and not as an agent or employee of CAPITALIKA MARKETS LLC. Introducer will not use the name CAPITALIKA MARKETS LLC in answering its telephone or in any other way represent itself to be associated with CAPITALIKA MARKETS LLC other than in relationship of an introducing broker to CAPITALIKA MARKETS LLC which holds the funds of introducer’s customers, and acts as a principal counterparty to introducer’s customers. In contracting for support services, such as rent, telephone, quotation services, utilities and thelike, introducer will not use or refer to the name «CAPITALIKA MARKETS  OR CAPITALIKA MARKETS LLC”.
                <p className={'contractsubTitles'}>3. INTRODUCED COUNTERPARTY, INITIAL SOLICITATION AND OPENING ACCOUNTS</p>
                A. For all introduced accounts, Introducer agrees to provide CAPITALIKA MARKETS LLC with all pertinentinformation with respect to each Introduced Counterparty. Without limiting the generality of the foregoing, Introducer agrees to furnish CAPITALIKA MARKETS LLC for each Introduced Account:<p></p>
                <p className={"contractClausules"}>a. the name, address, and principal occupation or business of the beneficial owner for whom the Introduced Account is maintained, the signature of such beneficial owner (or of the persons authorized to act on behalf of such beneficial owner), the name and address of any other persons who guarantee the Introduced Account, exercise any trading control or otherwise trading in the Introduced Account, or have any direct or indirect interest in the Introduced Account;</p>
                <p className={"contractClausules"}>b. a signed copy of all written agreements with respect to the Introduced Account;</p>
                <p className={"contractClausules"}>c. a copy of all account cards or records relating to the opening and maintenance of the Introduced Account;</p>
                <p className={"contractClausules"}>d. a signed copy of the customer agreement and such other agreements as may be prescribed by CAPITALIKA MARKETS LLC with respect to the Introduced Account;</p>
                <p className={"contractClausules"}>e. a signed copy of any guarantee or of any power-of-attorney with respect to the Introduced Account;</p>
                <p className={"contractClausules"}>f. evidence of the authority of the person or persons authorized to transact business for the Introduced Account and of the genuineness of all certificates and other documents pertaining to the Introduced Account, all in such form as may be prescribed by CAPITALIKA MARKETS LLC;</p>
                <p className={"contractClausules"}>g. a signed acknowledgment of receipt of each risk disclosure statement (including the statement provided in the Customer Agreement) or disclosure document required by applicable law or CAPITALIKA MARKETS LLC;</p>
                <p className={"contractClausules"}>h. Introducer further agrees that it will not use any document or agreement in connection with the opening or maintenance of an account that has not</p>
                <p className={"contractClausules"}>i. been supplied or approved by CAPITALIKA MARKETS LLC.</p>
                B. The Introducer is prohibited from taking deposits from Introduced Counterparties on behalf of CAPITALIKA MARKETS LLC.<p></p>
                C. In case the Introducer refers other Introducing Brokers to CAPITALIKA MARKETS LLC, herein referred to as (Sub IB), the Introducer shall provide CAPITALIKA MARKETS LLC with a) Articles of Incorporation, b) a copy of a photo ID of all directors whose signatures appear on the Articles of Incorporation, for all of the Sub IB’s.
                <p className={'contractsubTitles'}>4. CAPITALIKA MARKETS LLC ́S OBLIGATION TO INTRODUCER</p>
                A. CAPITALIKA MARKETS LLC AGREES TO PERFORM ITS OBLIGATIONS UNDER THIS AGREEMENT DILIGENTLY AND IN COMPLIANCE WITH ALL APPLICABLE LAWS AND REGULATIONS.<p></p>
                B. In return for its services, with respect to each Introduced Counterparty, CAPITALIKA MARKETS LLC agrees to pay to Introducer and/or collect from such Introduced Counterparty and remit to Introducer the fees set forth on Schedules attached hereto (collectively, the "Introducer Fees"). Such Introducer Fees shall be paid with respect to each Introduced Counterparty as specified in Schedules attached hereto within fifteen (15) business days following the last day of the calendar month.<p></p>
                C. Should Introducer manage funds of Introduced Counterparties through any means, including but not limited to: an executed power of attorney; the use of multi- account manager; or is reasonable determined to be trading the account of Introduced Counterparties by CAPITALIKA MARKETS LLC through IP tracking or some other independent investigative tool of CAPITALIKA MARKETS LLC, Introducer’s monthly commission due, as set forth within the Schedule(s) attached hereto, shall not exceed thirty-five percent (35%) of Introducer’s aggregate Introduced Counterparties equity and/or thirty-five percent (35%) of the total equity of any single Introduced Counterparty account being managed. For purposes of this calculation, client equity at month end shall be utilized. Should commission totals exceed thirty-five percent (35%) of the total Introduce Counterparties equity or the client equity of any one Introduced Counterparty account, CAPITALIKA MARKETS LLC shall only be obligated to pay Introducer an amount equal to thirty-five percent (35%). All amounts above and beyond that shall be forfeited.<p></p>
                D. For all Introduced Counterparties referred to CAPITALIKA MARKETS LLC by Introducer and who enroll within any and all promotion, promotional offer, promotional program or any other offering made available by CAPITALIKA MARKETS LLC, including but not limited to: trading bonuses; deposit bonuses; general contests or new account bonuses, the Parties hereto agree that all Fee Schedules attached hereto, shall not be applicable and CAPITALIKA MARKETS LLC shall not be obligated to compensate Introducer for such referred business.<p></p>
                E. For all Introduced Counterparties referred to CAPITALIKA MARKETS LLC Foreign Exchange Managed Account or Foreign Exchange Managed Account Plus (collectively the “FXMA”) program by Introducer, CAPITALIKA MARKETS LLC agrees to pay to Introducer the fees set forth within the applicable Schedule attached hereto (collectively, the “FXMA Fees”). Introducer acknowledges and understands that Introducer Fees contained within other attached Schedules shall not apply for any and all Introduced Counterparties referred to the FXMA program and Introducer shall not be paid based upon trading volume. Such FXMA Fees shall be paid with respect to each Introduced Counterparty as specified in the FXMA Schedule, attached hereto, within fifteen (15) business days following the last day of the calendar month.<p></p>
                F. The Introducer Fees set forth in the selected Schedules, where applicable, shall be payable for the duration of CAPITALIKA MARKETS LLC`s relationship with the Introducer. Upon termination of this Agreement, by either party, for any reason, the Fee Schedule set forth in the selected Schedules, if applicable, shall no longer be payable and shall terminate in compliance with Section 10 of this Agreement.<p></p>
                G. CAPITALIKA MARKETS LLC will deliver promptly to Introducer copies of all account statements with respect to the account of the Introduced.
                <p className={'contractsubTitles'}>5. INTRODUCERS OBLIGATION TO CAPITALIKA MARKETS LLC</p>
                A. Introducer agrees to perform its obligations under this Agreement diligently and in compliance with all applicable laws, rules and regulations, governing Introducer’s activities.<p></p>
                B. Introducer shall notify CAPITALIKA MARKETS LLC in writing, immediately upon receipt or obtaining knowledge of any Introduced Counterparty complaint or pending or threatened action or proceeding by any Introduced Counterparty in respect of any alleged errors, corrections or other matters relating to any Introduced Counterparty transaction or account with CAPITALIKA MARKETS LLC. CAPITALIKA MARKETS LLC at its discretion shall have the exclusive right to respond to, adjust, settle or reconcile any such Introduced Counterparty complaint as such complaint pertains to CAPITALIKA MARKETS LLC.<p></p>
                C. Introducer shall be liable to any and all debit balances maintained in the account of any Introduced Counterparty should CAPITALIKA MARKETS LLC be unable to obtain payment from such Introduced Counterparty after ten (10) calendar days. Any such debit balance(s) shall be debited from any Introducer Fees due to Introducer. Should such debit balance(s) total amounts greater than the following months Introducer Fees owed to Introducer by CAPITALIKA MARKETS LLC, any outstanding balances shall be carried forward to the following month(s) until such time as any debit balance(s) are satisfied.<p></p>
                D. Introducer shall deliver to CAPITALIKA MARKETS LLC; copies of all promotional material used by introducer to solicit forex business, this material shall include but is not limited to all emails, all mailings, all websites and links to websites, any scripts used. Introducer is obligated to have all promotional material approved by CAPITALIKA MARKETS LLC before use.<p></p>
                E. Introducer shall notify CAPITALIKA MARKETS LLC immediately if there are any changes in the introducer's status for registration with the appropriate authorities.<p></p>
                F. Introducer shall notify CAPITALIKA MARKETS LLC immediately if introducer receives any subpoenas, due to any type of fraudulent business practices, including but not limited to; false claims, misleading promotional materials, or any customer complaint.<p></p>
                G. Introducer shall notify CAPITALIKA MARKETS LLC immediately if introducer fails to receive timely payment of fees, as outlined within the selected attached Schedule(s), if applicable, attached hereto, for its services from CAPITALIKA MARKETS LLC. Introducer agrees that should timely payment not be made by CAPITALIKA MARKETS LLC, for any and all reasons, including, but not limited to, administrative error or operational oversight, and Introducer fails to notify CAPITALIKA MARKETS LLC within sixty (60) days following the date of payment as outlined in section 4(B) of this Agreement, Introducer waives any and all rights to such payment and further waives any claims of restitution and/or unjust enrichment.
                <p className={'contractsubTitles'}>6. WARRANTIES, REPRESENTANTIONS AND COVENANTS</p>
                A. CAPITALIKA MARKETS LLC, in order to induce Introducer to enter into and to perform this Agreement, does hereby represent and covenant to Introducer as follows:<p></p>
                <p className={"contractClausules"}>a. CAPITALIKA MARKETS LLC has the right and power to enter into and perform its duties under this Agreement; has taken all requisite corporate action to authorize the execution, delivery and performance of this Agreement and the transactions contemplated herein; and that this Agreement has been duly authorized, executed and delivered by CAPITALIKA MARKETS LLC and is binding upon and enforceable against it in accordance with the terms hereof.</p>
                <p className={"contractClausules"}>b. Neither the execution, delivery or performance of this Agreement by, nor the consummation of, any transaction contemplated otherwise, conflict with, result in a breach of, or constitute a default under or violate, as the case may be,i) The Operating Agreement of CAPITALIKA MARKETS LLC, b) Any foreign, federal, state or local law, statute, ordinance, rule or regulation, c) Any courtor</p>
                <p className={"contractClausules"}>c. administrative order of process, or d) Any contract, agreement, arrangement, commitment or plan of CAPITALIKA MARKETS LLC to which or by which CAPITALIKA MARKETS LLC may be bound.</p>
                B. Introducer, in order to induce CAPITALIKA MARKETS LLC to enter into and to perform this Agreement does hereby represent and covenant to CAPITALIKA MARKETS LLC as follows:
                <p className={"contractClausules"}>a. If Introducer is an entity, it is duly organized, validly existing and in good standing under the laws of its country of organization, and is qualified to do business in such other countries as the nature of its business activities and properties therein may require.</p>
                <p className={"contractClausules"}>b. Introducer shall not employ, on accounts held in their name, or introduce or assist any client at CAPITALIKA MARKETS LLC whether it be acting in concert with, or acting as an introducer, trader or otherwise for, such client account, any scripts, codes, electronic advisors, software or any other form of computer program that will either be utilized in order to entered into transactions for purposes of scalping, arbitrage or to intentionally exploit or manipulate any inaccuracy, regardless of how minor, major, obvious or unobvious, in any rate or pricing offered by CAPITALIKA MARKETS LLC or to circumvent controls or manipulate the systems of CAPITALIKA MARKETS LLC in order to delay feeds, obtain off-market pricing, or otherwise provide beneficial market conditions for the applicable account(s). CAPITALIKA MARKETS LLC defines scalping as any transaction which remains open for a period of less than three minutes in duration. Introducer agrees any and all introducer Fees as set forth within Section 4(b) herein shall not apply to transactions conducted by an Introduced Counterparty which fall under the definition and/or descriptions of Section 6(B) (iii) herein regardless of Introducer’s knowledge of such activities.</p>
                <p className={"contractClausules"}>c. Introducer has the right and power to enter into and perform its duties under this Agreement; has taken all requisite corporate action to authorize the execution, delivery and performance of this Agreement and the transactions contemplated herein; and that this Agreement has been duly authorized, executed and delivered by Introducer and is binding upon and enforceable against it in accordance with the terms hereof.</p>
                <p className={"contractClausules"}>d. Neither the execution, delivery or performance of this Agreement by nor the consummation of any transaction contemplated otherwise, conflict with, result in a breach of, or constitute a default under or violate, as the case may be,</p>
                <p className={'contractsubClausules'}>i. The charter documents of Introducer</p>
                <p className={'contractsubClausules'}>ii. Any foreign, federal, or local law, statute, ordinance, rule or regulation</p>
                <p className={'contractsubClausules'}>iii. Any court of administrative order or process, or</p>
                <p className={'contractsubClausules'}>iv. Any contract, agreement, arrangement, commitment or plan of Introducer to which or by which Introducer may be bound</p>
                <p className={"contractClausules"}>e. Introducer will not engage in any activity that would require Introducer to be a member of the Federal Deposit Insurance Corporation (“FDIC”) or National Futures Association (“NFA”) or Financial Industry Regulatory Authority (“FINRA”).</p>
                <p className={'contractsubClausules'}>i. Introducer will not solicit or introduce Canadian, Australian, and/or US citizens or permanent residents, or Canadian, Australian, and/or US entities.</p>
                <p className={'contractsubClausules'}>ii. Introducer will not operate its business or solicit from the US or any territories that fall under the US jurisdiction, rules and regulations.</p>
                <p className={"contractClausules"}>f. Introducer consents to, and agrees to assist CAPITALIKA MARKETS LLC with, any background check of any employees of Introducer.</p>
                <p className={"contractClausules"}>g. Introducer shall, at CAPITALIKA MARKETS LLC’s request, provide CAPITALIKA MARKETS LLC with a copy of its quarterly and year-end financial statements and reports of its auditors, if any, as and when such reports are prepared. Introducer further agrees to make its books and records available for reasonable inspection at all times by duly authorized representatives of CAPITALIKA MARKETS LLC</p>
                <p className={"contractClausules"}>h. Introducer agrees that, upon request, it will provide CAPITALIKA MARKETS LLC with any information in Introducer's possession with respect to any customer or any account.</p>
                <p className={"contractClausules"}>i. Introducer agrees that, upon request, CAPITALIKA MARKETS LLC may inspect any research report or other sales literature used or to be used by Introducer.</p>
                <p className={"contractClausules"}>j. Introducer agrees during the term of this Agreement and for two (2) years following the termination hereof, that Introducer will not (i) directly or indirectly solicit for employment or engagement any of CAPITALIKA MARKETS LLC's employees, consultants or agents or any other person otherwise engaged by CAPITALIKA MARKETS LLC; (ii) solicit or attempt to solicit for Introducer or any third party, any of CAPITALIKA MARKETS LLC's clients or counterparties with the intent or purpose of providing services to such client or counterparty similar to the services currently provided, or contemplated to be provided, to such clients or counterparties by CAPITALIKA MARKETS LLC; or (iii) induce</p>
                <p className={"contractClausules"}>k. or attempt to induce any employee, consultant or agent of CAPITALIKA MARKETS LLC to discontinue services to CAPITALIKA MARKETS LLC.</p>
                <p className={"contractClausules"}>l. Introducer understands and agrees that all spreads offered on the trading platforms of CAPITALIKA MARKETS LLC are variable and can fluctuate depending on volatility and liquidity within the market.</p>
                C. Each party, in order to induce the other party to enter into and to perform this Agreement, does hereby represent and covenant to the other party as follows:<p></p>
                <p className={"contractClausules"}>Each party shall keep confidential any information such party may acquire as the result of this Agreement regarding the business and affairs of the other party, and shall make available to third parties only such agreements, documents and papers supplied by the other party as may be authorized by the other party in writing or pursuant to any order, subpoena or other process of a court or regulatory body of competent jurisdiction. Each party shall give the other party prompt notice of the receipt by such party of any such order, subpoena or other process.</p>
                <p className={'contractsubTitles'}>7. OWNERSHIP, PROPIETARY INFORMATION</p>
                All copyright, trademark, trade secret and other intellectual property rights belonging to CAPITALIKA MARKETS LLC shall remain at all times the sole and exclusive property of CAPITALIKA MARKETS LLC. Introducer shall have no right or interest in any copyright, trademark, trade secret, or other intellectual property of CAPITALIKA MARKETS LLC. Introducer will not publish, distribute, or otherwise make available to third parties any information derived from CAPITALIKA MARKETS LLC or relating to any copyright, trademark, trade secret or other intellectual property rights belonging CAPITALIKA MARKETS LLC without the prior written consent of CAPITALIKA MARKETS LLC. Introducer will not copy, modify, de-compile, reverse engineer, and make derivative works of any intellectual property of CAPITALIKA MARKETS LLC.
                <p className={'contractsubTitles'}>8. INDEMNIFICATION</p>
                A. Introducer agrees to indemnify and hold CAPITALIKA MARKETS LLC, its directors, officers, shareholders, members, employees, agents, and each person, if any, controlling CAPITALIKA MARKETS LLC, harmless from any and all claims, demands, proceedings, suits, or actions (whether in law or in equity), and from any loss, damage, liability or expense, including reasonable attorneys’ fees and other legal expenses,to which CAPITALIKA MARKETS LLC, or any of its affiliates may become subject arising out of or relating to any act or omission of Introducer or any person connected, affiliated or associated with Introducer which is or is alleged to be a violation of domestic or foreign statutes, laws or regulations or arising from Introducer’s or such person’s alleged negligence or willful misconduct, or if Introducer fails to perform its obligations or breaches its representations, warranties, and covenants hereunder. Introducer will indemnify and hold CAPITALIKA MARKETS LLC harmless from any loss which CAPITALIKA MARKETS LLC may sustain as a result of errors made by Introducer, its agents, servants and employees in connection with the services provided to prospective or introduced counterparties hereunder. CAPITALIKA MARKETS LLC may, in its sole discretion, elect to assume the sole defense, including the settlement or compromise, of any such claim, demand, proceeding, suit, or action instituted against CAPITALIKA MARKETS LLC and Introducer.<p></p>
                B. Any aforesaid indemnification, hold harmless obligation, guaranty or loss sharing arrangement shall remain in effect without limit of time after the termination of this Agreement from any act or omission which shall have occurred during the period of this Agreement, whether discovered then or at any time subsequent to the termination of this Agreement.<p></p>
                C. Neither party shall be liable for any loss, damage, liability or expense resulting from any delay or default in performing hereunder if such delay or default is caused by conditions beyond its control including, but not limited to Acts of God, Government restrictions (including the denial or cancellation of any export or other necessary license), wars, insurrections and/ or any other cause beyond the reasonable control of the party whose performance is affected.<p></p>
                D. Introducer shall promptly notify CAPITALIKA MARKETS LLC in writing of the assertion of any material claim against Introducer by any Introduced Counterparty, or of the institution against Introducer or any affiliate, director, officer or employee thereof of any suit, action, investigation or proceeding by any Introduced Counterparty or by any regulatory agency, exchange or board of trade, and of every material development in any such claim, suit, action, investigation or proceeding. If Introducer is involved in any such customer complaint, civil suit, reparations, arbitration or regulatory proceeding or reasonably expects to become so involved, Introducer will cooperate with CAPITALIKA MARKETS LLC by furnishing all documents necessary to conduct an investigation and defend a claim or proceeding, unless it would be clearly prejudicial for Introducer to so cooperate. Without limiting the foregoing, Introducer will permit appropriate persons of CAPITALIKA MARKETS LLC or its attorneys, insurance representatives or auditors to interview employees of Introducer in the presence of representatives of Introducer.<p></p>
                E. In the event of any material claim against Introducer by any Introduced Counterparty, or of the institution against Introducer or any affiliate, director, officer or employee thereof of any suit, action, investigation or proceeding by any Introduced Counterparty or by any regulatory agency, exchange or board of trade, and of every material development in any such claim, suit, action, investigation or proceeding, CAPITALIKA MARKETS LLC shall withhold any portion or all of the Introducer's security deposit or any commissions due to the Introducer to offset any settlement or legal fees incurred by CAPITALIKA MARKETS LLC.<p></p>
                F. CAPITALIKA MARKETS LLC may at any time and in its sole discretion disclose the arrangement contemplated by this Agreement in any document or in any manner it deems accurate and appropriate.
                <p className={'contractsubTitles'}>9. EXPENSES</p>
                Each party shall be responsible for its own expenses incident to or arising out of this Agreement and neither party shall be obligated, without its prior written consent, to pay any such expenses of the other party
                <p className={'contractsubTitles'}>10. TERM; TERMINATION</p>
                A. CAPITALIKA MARKETS LLC or Introducer may terminate this Agreement immediately with or without cause, provided the terminating Party provide written notice of such termination delivered via electronic mailing and/or physical mailing to the applicable address provided. Upon dispatch of such written notice of termination the Agreement and any and all rights that do not specifically survive such termination shall terminate, including but not limited to, payments to Introducer due under the terms of this Agreement, will be extinguished.<p></p>
                B. Nothing in this Agreement shall prevent CAPITALIKA MARKETS LLC from exercising all of its rights under the respective customer agreements for the customers introduced by the Introducer, including without limitation, the right to liquidate or limit customer positions and the right to place customer accounts on a liquidation-only status.<p></p>
                C. Each party's rights of termination hereunder shall be in addition to all other remedies and rights to which it may be entitled under this Agreement.<p></p>
                D. In the event of termination of this Agreement, the terms and conditions of this Agreement shall survive and continue to apply to any and liabilities and obligations of Introducer which occurred prior to termination, were accrued prior to termination, or arose from events occurring prior to termination; including but not limited to, the Introducer’s obligation to indemnify, reimburse and cooperate with CAPITALIKA MARKETS LLC.
                <p className={'contractsubTitles'}>11. AMENDMENTS</p>
                This Agreement may not be amended or modified except in writing. CAPITALIKA MARKETS LLC may amend this Agreement if notice of such amendment is served to Introducer, in writing in accordance with Section 12 herein. This Agreement represents the entire understanding between the parties, and all prior discussions and negotiations are merged in it.
                <p className={'contractsubTitles'}>12. NOTICES</p>
                All notices and other communications hereunder shall be in writing and shall be deemed to have been duly given if sent via email to the applicable email addresses reported herein by each Party or by physical mailing to the address on file or to such other address as may be furnished by any party to the others inwriting.
                <p className={'contractsubTitles'}>13. LIQUIDATED DAMAGES</p>
                Introducer breaches any warranty, representation, obligation or other covenant contained within Sections 2, 5 or 6 within this Agreement, Introducer shall forfeit all Introducer Fees, including rebates, commission, and/or profit sharing (if applicable), paid under this Agreement since inception as liquidated damages to CAPITALIKA MARKETS LLC and Introducer agrees to make payment to CAPITALIKA MARKETS LLC in such aggregate amount within thirty (30) calendar days from written notice of such a breach. The Parties agree that quantifying losses arising from Introducer’s breach of any warranty, representation, obligation or other covenant contained within the applicable Sections of this Agreement is inherently difficult insofar as such breach may greatly impact CAPITALIKA MARKETS LLC’s reputation within the industry and the Parties further stipulate that the agreed upon sum is not a penalty, but rather a reasonable measure of damages, based upon the Parties’ experience in the industry and given the nature of the losses that may result from reputational damage.
                <p className={'contractsubTitles'}>14.GOVERNANCE; VENUE</p>
                This Agreement shall be governed by, and construed in accordance with, the laws of the Saint Vincent and the Grenadines without regard to the choice-of-law provisions thereof.
                <p className={'contractsubTitles'}>15. JURISDICTION; ARBITRATION</p>
                Any dispute, controversy or claim which may arise out of or in connection with this Agreement, or the execution, breach, termination or invalidity thereof, shall be settled by the International Commercial Arbitration Court at the Chamber of Commerce and Industry of the Saint Vincent and the Grenadines in accordance with its Rules. The language to be used in the arbitral proceedings shall be English and the place of arbitration shall be Saint Vincent and the Grenadines. The arbitral tribunal shall consist of no more than three (3) arbitrators and all proceedings shall be conducted, and a decision rendered based solely on the submission of documents and other such materials. No oral hearings or oral arguments shall be held. Client hereby waives the right to have such proceeding transferred to another arbitrator or jurisdiction. No arbitration hereunder or relating to this Agreement, shall be brought by Client more than one year after the date of the transaction giving rise to the cause for arbitration regardless of when Client discovers the facts relating to the cause for arbitration.
                <p className={'contractsubTitles'}>16. CONSENT TO SERVICE OF PROCESS</p>
                Introducer agrees to authorize CAPITALIKA MARKETS LLC to act as its Agent for the purposes of receipt of service of process in any legal proceedings initiated against Introducer. Upon receipt of service, CAPITALIKA MARKETS LLC shall by sending copies thereof by registered or certified mail, notify Introducer of such legal proceedings at the address set forth in this Agreement.
                <p className={'contractsubTitles'}>17. TRIAL BY JURY; STATUTE OF LIMITATIONS</p>
                Introducer acknowledges that it waives a trial by jury in any action arising out of or relating to this Agreement or any transaction in connection herewith. In addition, Introducer agrees to waive any and all other applicable statutes of limitations exceeding one year, including but not limited to, any statutory or common law state or federal statute of limitations.
                <p className={'contractsubTitles'}>18. INVALIDITY OR UNENFORCEABILITY</p>
                Any provision hereof which is prohibited, invalid or unenforceable shall be ineffective only to the extent of such prohibition, invalidity or unenforceability without invalidating the remaining provisions hereof.
                <p className={'contractsubTitles'}>19, INDEPENDENCE</p>
                This Agreement shall not be deemed to establish a joint venture or partnership between the parties, and Introducer shall not be deemed to be an agent or employee of CAPITALIKA MARKETS LLC. Neither Introducer nor any of its officers, employees and agents shall be deemed in any manner or under any circumstance to be an employee or agent of CAPITALIKA MARKETS LLC, and no such person shall be entitled to any of the rights, protections or benefits provided by CAPITALIKA MARKETS LLC to its employees, including without limitation, group insurance or any kind of pensions.
                <p className={'contractsubTitles'}>20. ASSIGNMENT; BINDING EFFECT</p>
                This Agreement shall be binding upon and inure to the benefit of the parties hereto and their respective successors and assigns, provided, however, neither this Agreement nor any rights hereunder shall be assignable by the Introducer without the express written consent of CAPITALIKA MARKETS LLC.
                <p className={'contractsubTitles'}>21.LANGUAGE</p>
                This Agreement may be translated into other languages but, in the event of any inconsistency or ambiguity as to the meaning of any word or phrase in any such translation, the English text shall prevail.
                <p className={'contractsubTitles'}>22. SECTION HEADING</p>
                The section headings in this Agreement have been inserted as a matter of convenience of reference only.
                <p className={'contractsubTitles'}>COUNTERPARTS</p>
                This Agreement may be signed by the parties in counterparts which together shall constitute one and the same agreement between the parties and shall become effective at such time as each of the parties shall have signed such counterparts and shall have notified the other party thereof.<p></p>
                <p className={'contractBold'}>IN WITNESS WHEREOF, the parties have executed this Agreement and attached Schedule(s) and incorporate all terms into the accompanying Introducing Agreement on the date first written</p>
                Please sign using the same signature that’s on your identification (or as close as possible)
            </div>
        )
    }
}
export default ibContract;