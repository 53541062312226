import React, { Component } from 'react';
import {Col,Row,Image,Button,Form } from 'react-bootstrap';
import './signUp.css'
import { Dots } from 'react-activity';
import moment from 'moment';
import ClientPDF from '../pdfs/client.pdf'
import IbClientPDF from '../pdfs/ib.pdf'
import Watermark from '../img/mundito.png'
import Contract from '../Contracts/contract';
import IbContract from '../Contracts/ibContract';
//import ContractHtml from '../Contracts/contract.html';
//import TheContract from '../Contracts/elcontrato.json';
import LogoGnt from '../img/logo_gnt.png';
import Backend from '../backend'
import SignatureCanvas from 'react-signature-canvas'
import TextContractEnterprise from "../Contracts/enterprise";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { store } from 'react-notifications-component';

var URLactual = window.location;
//var URLactual = 'https://contracts.gntcapital.com/?idgnt_whiteLevel_clientesInfo=1&hashEnvio=KJOJCTPUS66B&ib=1'
//var URLactual = 'https://contracts.gntcapital.com/?idgnt_validacion_email=1&hashEnvio=PUTO'
var fechacompleta = moment().format('LL');

class Individual extends Component{
  constructor(props){
    super(props);
    this.state = {
      step: 0,
      loading: false,
      ib:0,
      name:'',
      trimmedDataURL: null,
      trimmedDataURLib: null,
      declaration: false,
      ibdeclaration: false,
      signature:false,
      addressImg:null,
      addressImgName:'Select File',
      legalRepresentative:'Rod de Tecnología',
      organizationName:'Global Next Trade S.A. de C.V.',
      stateClient:'lg-sus'
    }
  }

  addressChange(e){

    const dataValid = new FormData()
    dataValid.append('UploadedImage', e.target.files[0]);
    var img=e.target.files[0]


    if(e.target.files[0]){
      if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpeg') {
        if(e.target.files[0].name.split('.').length-1 > 1){
          this.setState({addressError:'Invalid file'})
        }else if(e.target.files[0].size > 3500000){
          this.setState({addressError:'File size is too big'})
        }else{

          Backend.ValidarImagen(dataValid,response=>{
            if(response==="Archivo valido"){
              this.setState({addressImg: img, addressImgName: img.name,addressError:''})
            }else{
              this.setState({addressError:'Your file is not an image, upload a specified file'})
            }
          })

          //this.setState({addressImg: e.target.files[0], addressImgName: e.target.files[0].name,addressError:''})
        }
      }else {
        this.setState({addressError:'Invalid file'})
      }
    }
  }

  body(){
    switch(this.state.step){
      case 0: return(
        <Row>
              <Col>
                <div className={"login-contenedor-columna-2"}>
                  <Row>
                    <Col xs={12} className="marginFormTitle">
                      <p className={"login-titulo-center-columna-2"}>INDIVIDUAL CLIENT AGREEMENT</p>
                    </Col>
                  </Row>
                  <Row>
                  <div className={'scrollDiv'} >
                      <div className={'growth'} id='contratillo'>
                        <p className={'signUpTxt'}>
                          <Contract name={this.state.name}></Contract>
                          {this.state.declaration == true
                          ? this.sign()
                          : null
                          }
                      </p>
                      </div>
                      </div>
                      <br></br>
                      <p style={{color:'#fafafa', textAlign:'left'}} >Please read and sign the Individual Client Agreement</p>
                      <Form.Check type='checkbox' className={'agreeText'} checked={this.state.declaration} onClick={()=>{this.setState({declaration:!this.state.declaration})}} label='I Agree Individual Client Agreement'/>
                      <br></br>
                  </Row>
                  { this.state.ib == 1
                  ?<>
                  <br></br>
                  <Row>
                    <Col xs={12} className="marginFormTitle">
                      <p className={"login-titulo-center-columna-2"}>INTRODUCING BROKER AGREEMENT</p>
                    </Col>
                  </Row>
                  <Row>
                    <div className={'scrollDiv'} >
                      <div className={'growthib'} id='contratillo'>
                        <p className={'signUpTxt'}>
                          <IbContract name={this.state.name}></IbContract>
                          {this.state.ibdeclaration == true
                          ? this.signib()
                          : null
                          }
                      </p>
                      </div>
                      </div>
                      <br></br>
                      <p style={{color:'#fafafa', textAlign:'left'}} >Please read and sign the Introducing Broker Agreement</p>
                      <Form.Check type='checkbox' className={'agreeText'} checked={this.state.ibdeclaration} onClick={()=>{this.setState({ibdeclaration:!this.state.ibdeclaration})}} label='I Agree Ib Agreement'/>
                  </Row>
                  </>
                  :null
                  }
                </div>
              </Col>

            <br></br>

            <Col xs={{offset:1,span:10}}>
              {this.state.ib == 1
              ?<Button bsStyle="primary"className={this.state.trimmedDataURL && this.state.trimmedDataURLib ?"buttonRegisterContinue":"buttonRegisterContinueDisabled"} disabled={this.state.trimmedDataURL && this.state.trimmedDataURLib ?false :true} onClick={()=>this.sendContactInstitutionalPDF()}>{(this.state.loading)?<Dots color="#fff" size={32} speed={1} animating={this.state.loading}/>:'Confirm Contract'}</Button>
              :<Button bsStyle="primary"className={this.state.trimmedDataURL ?"buttonRegisterContinue":"buttonRegisterContinueDisabled"} disabled={this.state.trimmedDataURL ?false :true} onClick={()=>this.sendContactInstitutionalPDF()}>{(this.state.loading)?<Dots color="#fff" size={32} speed={1} animating={this.state.loading}/>:'Confirm Contract'}</Button>
              }
              
            </Col>
          </Row>
        );
      }
  }

  clear() {
    const self = this;
    self.sigPad.clear();
    self.setState({
      trimmedDataURL: null
    })
  }

  clearib() {
    const self = this;
    self.sigPadib.clear();
    self.setState({
      trimmedDataURLib: null
    })
  }

  componentDidMount(){
    var URLactual = window.location;
    //document.getElementById("contratillo").innerHTML=TheContract.list-WL;
    this.getClientName()
  }

  createCabinetAccount(){
    alert("Create")
  }

  downloadBlob(data, fileName, mimeType){

    const blob = new Blob([data], {
      type: mimeType
    })

    const url = window.URL.createObjectURL(blob)

    this.downloadURL(url, fileName)

    setTimeout(() => window.URL.revokeObjectURL(url), 1000)
  }

  downloadURL(data, fileName){
    const a = document.createElement('a')
    a.href = data
    a.download = fileName
    document.body.appendChild(a)
    a.style.display = 'none'
    a.click()
    a.remove()
  }

  firmaCompletada() {
    const self = this;
    var sefg = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    self.setState({
      trimmedDataURL: sefg
    });
  }

  firmaCompletadaib() {
    const self = this;
    var sefg = this.sigPadib.getTrimmedCanvas().toDataURL('image/png');
    self.setState({
      trimmedDataURLib: sefg
    });
  }

  getClientName(){
    
    if(URLactual.toString().indexOf("idgnt_whiteLevel_clientesInfo") != -1){
     var codigoDespues =URLactual.toString().split("idgnt_whiteLevel_clientesInfo=")
    }
    else {
     var codigoDespues =URLactual.toString().split("idgnt_validacion_email=")
    }
    var codigo = codigoDespues[1].split("&")[0]
    var codigoOtro = codigoDespues[0].split("&")[0]
    var codigoDespues =URLactual.toString().split("hashEnvio=")
    var hash = codigoDespues[1].split("&")[0]
    var codigoName =URLactual.toString().split("ib=")
    this.setState({ib:codigoName[1]}, this.setIbStatus(codigoName[1]))
    Backend.contractApprover(codigo,"NCT",response =>{
      response = JSON.parse(response)
      this.setState({name:response[0].Name.toUpperCase()})
    });
  }

  async sendContactInstitutionalPDF() {
    
    store.addNotification({
      title: "Contract!",
      message: "Sending the contract....",
      type: "info",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });

    const self = this;
    this.setState({declaration: false, ibdeclaration: false,})

    if(URLactual.toString().indexOf("idgnt_whiteLevel_clientesInfo") != -1){
     var codigoDespues =URLactual.toString().split("idgnt_whiteLevel_clientesInfo=")
    }
    else {
     var codigoDespues =URLactual.toString().split("idgnt_validacion_email=")
    }
    var codigo = codigoDespues[1].split("&")[0]
    var codigoDespues =URLactual.toString().split("hashEnvio=")
    var hash = codigoDespues[1].split("&")[0]
    var codigoName =URLactual.toString().split("ib=")
    var ib = codigoName[1]

    const clientDocumentPdfBytes = await fetch(ClientPDF).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(clientDocumentPdfBytes)
    const TimesRomanBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
    // const clientDocumentPdfDoc = await PDFDocument.load(fs.readFileSync('../pdfs/client.pdf'));

    let pdfPages =  pdfDoc.getPages()

    const firstPage = pdfPages[0]
    firstPage.drawText(fechacompleta, {
      x: 493,
      y: firstPage.getHeight() - 144,
      size: 12,
      color: rgb(0, 0, 0),
      font:TimesRomanBold
    })

    firstPage.drawText(this.state.name, {
      x: 280,
      y: firstPage.getHeight() - 158,
      size: 12,
      color: rgb(0, 0, 0),
      font:TimesRomanBold
    })

    const lastPage = pdfPages[pdfPages.length - 1]
    const pngImageBytes = await fetch(this.state.trimmedDataURL).then((res) => res.arrayBuffer())
    const pngImage = await pdfDoc.embedPng(pngImageBytes)
    const pngDims = pngImage.scale(0.5)

    lastPage.drawImage(pngImage, {
      x: 190,
      y: 170,
     width: pngDims.width,
     height: pngDims.height,
   })


   lastPage.drawText(fechacompleta + "\n\n\n\n\n\n\n\n\n"  + this.state.name + "\n" , {
    x: lastPage.getWidth() / 2 - pngDims.width / 2 - 25,
    y: lastPage.getHeight() / 2 - pngDims.height + 50,
    size: 12,
    color: rgb(0, 0, 0),
    font:TimesRomanBold
  })

    if(this.state.ib == 1){
      const ibPdfBytes = await fetch(IbClientPDF).then(res => res.arrayBuffer())
      const ibPdfDoc = await PDFDocument.load(ibPdfBytes)
      const ibPdfPages = ibPdfDoc.getPages();


      let [clientDocumentPage] = await pdfDoc.copyPages(ibPdfDoc, [0])
      const firstPageIb = pdfDoc.addPage(clientDocumentPage)
      firstPageIb.drawText(fechacompleta, {
        x: 440,
        y: firstPage.getHeight() - 102,
        size: 12,
        color: rgb(0, 0, 0),
        font:TimesRomanBold
      })

      firstPageIb.drawText(this.state.name, {
        x: 282,
        y: firstPage.getHeight() - 120,
        size: 12,
        color: rgb(0, 0, 0),
        font:TimesRomanBold
      })

      for (var i = 1; i < ibPdfPages.length - 1; i++) {
        [clientDocumentPage] = await pdfDoc.copyPages(ibPdfDoc, [i])
        pdfDoc.addPage(clientDocumentPage)
      }

      [clientDocumentPage] = await pdfDoc.copyPages(ibPdfDoc, [ibPdfPages.length - 1])
      const pngImageBytesib = await fetch(this.state.trimmedDataURLib).then((res) => res.arrayBuffer())
      const pngImageib = await pdfDoc.embedPng(pngImageBytesib)
      const pngDims = pngImageib.scale(0.5)
      const lastPageIb = pdfDoc.addPage(clientDocumentPage)
      lastPageIb.drawImage(pngImageib, {
        x: 190,
        y: 226,
        width: pngDims.width,
        height: pngDims.height,
      })

      lastPageIb.drawText(fechacompleta + "\n\n\n\n\n\n\n\n\n"  + this.state.name + "\n" , {
        x: lastPage.getWidth() / 2 - pngDims.width / 2 - 25,
        y: lastPage.getHeight() / 2 - pngDims.height + 130,
        size: 12,
        color: rgb(0, 0, 0),
        font:TimesRomanBold
      })

    }

    const pdfBytes = await pdfDoc.save()
    const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
    const formattedPdfDataUri = pdfDataUri.split(",")[1]
    
/*  */
  if(URLactual.toString().indexOf("idgnt_whiteLevel_clientesInfo") != -1){
    Backend.UploadDoc(formattedPdfDataUri,codigo,hash,response =>{
      response = JSON.parse(response)
      if(response[0].ENVIO == 'Solicitud ya enviada...'){
        store.addNotification({
          title: "Warning!",
          message: response,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        return
      }
      if(!response){
        store.addNotification({
          title: "Error!",
          message: response,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      else{
        //alert('Contrato enviado correctamente')
        store.addNotification({
          title: "Success!",
          message: "Contract send Correctly",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          },
      onRemoval: ()=>{
        window.location.replace("https://markets.capitalika.com/")
      }
        });
        this.downloadBlob(pdfBytes, "CapitalikaMarkets_AGREEMENT.pdf", "application/pdf")
        window.location.replace("https://markets.capitalika.com/")
        this.clear()
        this.setState({declaration:!this.state.declaration})        
      }
    }); 
  }
  else {
    Backend.UploadDemoLiveDoc(formattedPdfDataUri,codigo,hash,response =>{
      response = JSON.parse(response)
      if(response[0].ENVIO == 'Solicitud ya enviada...'){
        store.addNotification({
          title: "Warning!",
          message: response,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        return
      }
      if(!response){
        store.addNotification({
          title: "Error!",
          message: response,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      else{
        //alert('Contrato enviado correctamente')
        store.addNotification({
          title: "Success!",
          message: "Contract send Correctly",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          },
          onRemoval: ()=>{
            window.location.replace("https://markets.capitalika.com/")
          }
        });
        this.downloadBlob(pdfBytes, "CapitalikaMarkets_AGREEMENT.pdf", "application/pdf")
        window.location.replace("https://markets.capitalika.com/")
        this.clear()
        this.setState({declaration:!this.state.declaration})        
      }
    });

    }
   
    //this.downloadBlob(pdfBytes, "CapitalikaMarkets_AGREEMENT.pdf", "application/pdf")
  }

  setIbStatus(ib){
    if(ib == 1) this.setState({ibdeclaration:false})
    else this.setState({ibdeclaration:true})
  }

  sign(){
    return(
      <Row>
        <Col xs={12} className="marginFormTitle">
          <SignatureCanvas
            penColor='blue'
            backgroundColor='white'
            canvasProps={{ width: 330, height: 200, className: 'sigCanvas' }}
            onEnd={() => { this.firmaCompletada() }}
            ref={(ref) => { this.sigPad = ref }}
          />
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant="link" onClick={() => { this.clear() }}>Clear</Button>
          </div>
        </Col>
        <br></br>
      </Row>
    )

  }

  signib(){
    return(
      <Row>
        <Col xs={12} className="marginFormTitle">
          <SignatureCanvas
            penColor='blue'
            backgroundColor='white'
            canvasProps={{ width: 330, height: 200, className: 'sigCanvas' }}
            onEnd={() => { this.firmaCompletadaib() }}
            ref={(ref) => { this.sigPadib = ref }}
          />
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant="link" onClick={() => { this.clearib() }}>Clear</Button>
          </div>
        </Col>
        <br></br>
      </Row>
    )

  }

  render(){
    return(
      <div className={'backgroundSI'}>
        <ReactNotification />
        <Row className={'titleDiv'} >
          <Col xs={12} md={12} className="centerDiv">
            <Image src={LogoGnt} className={"login-logo-gnt"} />
          </Col>
        </Row>
        {this.body()}
      </div>
    )
  }

  }
  export default Individual;
